@import "../../../styles/variables.scss";

.carouselWrapper {
  width: 100%;
  overflow-x: hidden;
  position: relative;

  @media only screen and (max-width: $sm-screen) {
    overflow-x: scroll;
  }
}

.carouselInnerWrapper {
  display: flex;

  .card {
    width: 275px;
    margin-right: 20px;
  }
}

.paddles {
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 1;

  @media only screen and (max-width: $sm-screen) {
    display: none;
  }
}

.paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 28px;
  height: 28px;
  text-align: center;
  padding-top: 2px;
  display: inline-block;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
  cursor: pointer;
}

.leftPaddle {
  left: -15px;
}

.rightPaddle {
  right: -15px;
}


.limitOverflow {
  width: 100%;
  overflow: hidden;
}

.cont {
  width: 1222px !important;
  @media (max-width: 1200px) {
    width: 100% !important;
  }
  height: 100%;
  position: static !important;
}
