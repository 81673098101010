@import "../../../styles/variables.scss";

.rating {
  display: inline-block;
  padding: 5px 11px;
  color: white;
  margin-right: 6px;
  border-radius: 15px;
  cursor: pointer;
  box-shadow: $box-shadow-card;
  transition: box-shadow 0.2s;
  font-size: 15px;
  background: white;
}

.rating:hover {
  box-shadow: $box-shadow-card-hover;
}

.ratingRed {
  background: $red-700;
  border: 1px $red-700 solid;
}

.ratingRed:focus {
  border: 1px $red-700 solid;
}

.ratingGreen {
  background: $dark-green;
  border: 1px $dark-green solid;
}

.ratingGreen:focus {
  border: 1px $dark-green solid;
}

.ratingYellow {
  background: $yellow-600;
  border: 1px $yellow-600 solid;
}

.ratingYellow:focus {
  border: 1px $yellow-600 solid;
}

.ratingUnrated {
  color: $grey-400;
  border: 1px $grey-400 solid;
}

.ratingUnrated:focus {
  border: 1px $sushi-gray solid;
}

.invisible {
  opacity: 0;
}
