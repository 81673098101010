@import "../../../styles/variables.scss";

.loader {
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
  margin: auto;
  position: relative;
  margin-top: 40px;
}

.loader > div {
  margin: auto;
  width: 15px;
  height: 15px;
  display: inline-block;
  background: $red-border;
  border-radius: 50%;
  margin: 3px;
  animation: loader 0.5s infinite alternate;
}

.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loader {
  to {
    opacity: 0.1;
    transform: translate(0, -20px);
    background: $red-border;
  }
}

.container {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
