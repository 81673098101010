@import "../../styles/variables.scss";

.marginTop{
    margin-top: 85px;
    @media screen and (max-width: 992px ){
      padding: 0%;
      margin-top: -70px;
      overflow-y: scroll;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }
}

.widthInherit {
  width: inherit;
  height: 100%;
}

.backgroundMain{
  height: 80vh;
  overflow-x: hidden;
  @media screen and (max-width: 992px ){
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
}

.setupBackgroundImage{
  height: 380px;
  width: 100%;
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
  position:relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/assets/images/CompleteSetupPage.png");

  @media screen and (max-width: 992px ){
    height: 296px;
    display: inline-block;
    background-image: url("../../../public/assets/images/OnboardingBackgroundForMobile.png");
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.onboardinModalIcon{
  visibility: hidden;
  @media screen and (max-width: 992px ){
    visibility: visible;
    top: 85px;
    display: flex;
    justify-content: start;
    align-items: start;
    left: 15px;
    width: 24px;
    height: 24px;
  }
}

.cafeImage{
  right: 2%;
  top: -3%;
  height: 400px;
  width: 350px;
  background-image: url("../../../public/assets/images/cafePhotoSetupPage.png");
  @media screen and (max-width: 992px ){
    background-image: url("../../../public/assets/images/cafeMobile.png");
    right: -5%;
    top: 120px;
    height: 160px !important;
    width: 160px !important;
    background-size: contain;
  }
}

.cafeInlineImage{
  width: 100%;
  display: flex;
  justify-content: center;
  
  @media screen and (max-width: 992px ){
    bottom: 45px;
    left: 0px;
    height: 2px !important;
  }
}

.cafeInlineImageDiv{
  background-image: url("../../../public/assets/images/OnboardingLine.png");
  
  
  @media screen and (max-width: 992px ){
    background-size: cover;
    border-top: 1px solid white;
    width: 87.2%;
    height: 2px;
    text-align: center;
    opacity: 0.2;
  }
}

.heading{
  position:absolute;
  left: 60px;
  top: 64px;
  font-size: 36px; //move this
  color: #FFFFFF;
  @media screen and (max-width: 992px ){
    left: 24px;
    top: 120px;
    font-size: 20px;
  }
}

.subHeading{
  left: 60px;
  top: 152px;
  color: #FFFFFF;
  font-size: 18px; //move this
  margin-top: 8px;
  opacity: 0.8;
  @media screen and (max-width: 992px ){
    left: 24px;
    top: 170px;
    color: #FFFFFF;
    width: 50%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400px;
    opacity: 0.8;
  }
}

.discountBox{
  left: 60px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(255, 255, 255, 0.12);
  padding: 8px 12px;
  top: 210px;
  color:#FFFFFF;
  width: 268px;
  height: 40px;
  @media screen and (max-width: 992px ){
    width: 100%;
    left: 0%;
    top: 250px;
    background: none;
    border: none;
    justify-content: center !important;
  }
}

.bottomTagImage{
  width: 18px;
  height: 18px;
}

.notesLine{
  text-align: center;
}

.line{
  width: 1040px;
  stroke: var(--indigoindigo-100, #E8EAF5);
}

.horizontalDash{
  width: 1040px;
  color: $gray-med !important;
  background-color: $gray-med;
  border: none;
  border-top:1px solid $gray-med;
  opacity: 0.4;
  height: 0px;
  stroke-width: 1px;
  stroke: var(--indigoindigo-100, #E8EAF5);
  @media screen and (min-width: 575px) and (max-width: 992px) {
    margin-top: 5%;
    width: 100%;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 575px ){
    margin-top: 5%;
    width: 87.2%;
    margin-bottom: 5px;
  }
}

.listColorMainBox{
  @media screen and (max-width: 992px ){
    display: flex;
    justify-content: center;
  }
}

.listColor{
  color: $gray-med !important;

  @media screen and (min-width: 575px) and (max-width: 992px) {
    width: 100% !important;
    margin-bottom: 100px;
  }

  @media screen and (max-width: 575px ){
    width: 87% !important;
    margin-bottom: 100px;
  }




}

.stepsBox{
  top: 314px;
  left: 60px;
  display: flex;
  justify-content: space-around;
  gap: 40px;
  position: absolute;
  width: 1040px;

  @media screen and (min-width:900px) and (max-width: 1200px ){
    width: 87% !important;
  }

  @media screen and (min-width: 575px ) and (max-width: 992px ){
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100% !important;
    position: static;
    margin-top: 24px;
  }

  @media screen and (max-width: 575px ){
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 87% !important;
    position: static;
    margin-top: 24px;
  }

}

.stepsCard{
    border-radius: 32px;
    background-color: #FFFFFF;
    flex: 1 0 0;
    height: 268px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 992px ){
      min-height: 88px;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
    }
}

.stepsImages{
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin-top: 24px;
  margin-bottom: 12px;

  @media screen and (max-width: 992px ){
    width: 56px !important;
    height: 56px !important;
    margin-left: 16px;
  }
}

.cartButtonText{
  font-size: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width:992px) {
    font-size: 16px;
    width: auto;
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.siteBackgroundColor{
  background-color: #f4f6fb;
  height: 100% !important;
  @media screen and (max-width: 992px ){
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.cardButtons{
  margin-top: 32px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  @media screen and (max-width: 992px ){
    width: 10%;
    height: 32px;
    margin-top: 5px;
    margin-right: 10%;
    justify-content: flex-end;
    position: absolute;
    right: 0%;
  }
}

.buttonWidth{
  width: 80%;
  @media screen and (max-width: 992px ){
    width: 59px !important;
  }
}


.cardButton{
  width: 100% !important;
  span{
    border-radius: 12px;
  }

  @media screen and (max-width: 992px ){
    span{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      width: 59px !important;
      height: 32px !important;
      min-height: 32px !important;
      min-width: 59px !important;
    }
  }

  @media screen and (max-width: 992px ){
      width: 59px !important;
      height: 32px !important;
      min-height: 32px !important;
      min-width: 59px !important;
      position: absolute;
      right: 0%;
      top: 0%;
  }
}

.completedButton :hover{
  background: #EBFFEF;
  background-color: #EBFFEF;
  color: #24963F;
}

.completedButton{
  background-color: #EBFFEF;
  color: #24963F;
  font-weight: 400px;
  width: 85%;
  height: 45px;
  padding: 8px 16px;
  border-radius: 12px;
}

.lockedButton span{
  background: #F2F4F7;
  background-color: #F2F4F7;
  color: #24963F;
  font-weight: 400px;
}

.lockedButton :hover{
  background: #F2F4F7;
  background-color: #F2F4F7;
  color: #24963F;
}

.headerVisible{
  visibility: visible;
  @media screen and (max-width:992px) {
    visibility: hidden;
    display: none;
  }
}