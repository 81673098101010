@import "../../../styles/variables.scss";
.modal {
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.cardWrap {
    border: 1px solid $grey-200;
    border-radius: 20px;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
}

.popup {
    width: 100%;
    max-width: 700px;
    position: relative;
    animation-name: popup;
    animation-duration: 0.32s;
    animation-direction: forward;
    animation-timing-function: ease-in-out;
    .cross {
        position: absolute;
        right: 10px;
        top: 7px;
    }
    img {
        width: 100%;
    }
}

@keyframes popup {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.modalSide {
    position: fixed;
    bottom: 10px;
    z-index: 1000;
    margin-top: 12px;
    border-radius: 8px;
    max-width: 210px;
    right: 20px;
    @media (min-width: 576px) {
        right: calc(50% - 250px);
    }
    @media (min-width: 768px) {
        right: calc(50% - 340px);
    }
    @media (min-width: 992px) {
        right: calc(50% - 460px);
    }
    @media (min-width: 1200px) {
        right: calc(50% - 550px);
    }
    .modalSideCross {
        left: -9px;
        color: #fff;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        position: absolute;
        z-index: 1;
        text-align: center;
        padding-top: 2px;
        top: -9px;
        background: #D6D6D6;
    }
}

.modalSideTemplate1 {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    .inner {
        border-radius: 4px;
        padding: 10px;
    }
}

.modalSideTemplate2 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 5px 20px rgba(28, 28, 28, 0.2);
    padding: 20px;
    text-align: center;
    img {
        width: 50px;
    }
    .text1 {
        font-size: 9px;
        letter-spacing: 4px;
        color: #1c1c1c;
        margin-top: -6px;
    }
    .text2 {
        font-size: 18px;
        font-family: "OurLexend-Medium", Helvetica, sans-serif;
        margin-bottom: 22px;
    }
    .text3 {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 22px;
    }
    button {
        background: transparent;
        border: 1px solid $pri-black;
        border-radius: 4px;
        padding: 9px 10px;
        cursor: pointer;
    }
}

.modalSideTemplate2Light {
    .text1 {
        color: $pri-black;
    }
    .text2 {
        color: $pri-black;
    }
    .text3 {
        color: $pri-black;
    }
    button {
        border: 1px solid $pri-black;
        color: $pri-black;
    }
}

.modalSideTemplate2Dark {
    .text1 {
        color: white;
    }
    .text2 {
        color: white;
    }
    .text3 {
        color: white;
    }
    button {
        border: 1px solid white;
        color: white;
    }
}

.gstpopup {
    height: 300px;
    @media (max-width: 500px) {
        height: 250px;
    }
    width: inherit;
    border-radius: 24px 24px 0px 0px;
}

.offerModalBackgroundImage {
    height: 204px;
    width: inherit;
    border-radius: 24px 24px 0px 0px;
}

.offerModalForegroundImage {
    height: 154.6px;
    width: 153.6px;
}

.offerModalCta {
    height: 56px;
    span {
        border-radius: 12px;
        font-size: 18px;
        font-weight: 400;
    }
}

.offerModalContainer {
    width: 480px;
    border-radius: 24px 24px 24px 24px;
}

.offerModalCtaContainer {
    border-radius: 0px 0px 24px 24px;
    box-shadow: 0px -2px 16px 0px rgba(28, 28, 28, 0.04);
    border-top: 1px solid var(--greygrey-200, #EBEBEB);
}

.gstmodalWidth {
    width: 400px;
    @media (max-width: 500px) {
        width: 250px;
    }
}

.verifyLoctionWidth {
    width: 480px;
    margin-top: 40px;
}
.universalModalWidth {
    width: 480px;
}

.greytext {
    color: #696969;
}

.lightGreyFooterText {
    color: $gray-500;
}

.modalRadius {
    >div:nth-child(2) {
        border-radius: 24px;
    }
}

.modalWidth250 {
    >div:nth-child(2) {
        width: 250px;
    }
}

.width80 {
    width: 80px;
    height: 80px;
}

.gray900 {
    color: #363636;
}

.gray700 {
    color: #696969;
}

.red500 {
    color: #EF4F5F;
}

.width25 {
    width: 25vw;
}

.width40 {
    width: 40vw;
}

.wd60 {
    width: 60%;
}

.img56 {
    height: 56px;
    width: 56px;
}

.img120 {
    height: 120px;
    width: 120px;
}

.width420px {
    width: 420px;
}

.width480px {
    width: 480px;
}

.greyFont {
    color: $grey-600
}

.bottomCta {
    border-top: 1px solid #F2F4F7;
    padding: 12px;
}

.successMediaJson {
    position: absolute;
    top: -218px;
}

.mt40px{
  margin-top: 40px;
}

.img200px{
  width: 200px;
  height: 200px;
}

.img120px{
    width: 120px;
    height: 120px;
}

.btnBorder{
    span{
        border-radius: 12px;
    }
}