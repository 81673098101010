@import "../../../styles/variables.scss";

.paddingModal{
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 20px;
    @media screen and (max-width:992px) {
        height: calc(100vh - 250px);
        overflow-y: scroll;
        scrollbar-width: none;
        scroll-behavior: smooth;
    }
}

.paddingModalWithBottomMessage{
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 20px;
    @media screen and (max-width:992px) {
        height: calc(100vh - 322px);
        overflow-y: scroll;
        scrollbar-width: none;
        scroll-behavior: smooth;
    }
}


.subHeadingColor {
    color: $grey-600;
}

.btnBottomBar {
    position: absolute;
    bottom: 0;
    padding: 24px;
    width: 100%;
    box-shadow: 0px -2px 16px 0px rgba(28, 28, 28, 0.04);
    border-top: 1px solid var(--coolgreycoolgrey-200, #E6E9EF);
}

.btnBorder{
    span {
       border-radius: 12px !important;
    }
}

.loaderPoisiton {
    position: absolute;
    bottom: 102px;
}

.widthInherit {
    width: inherit !important;
    height: inherit;
}

.bottomNudge{
    width: 100%;
    height: 72px;
    background: var(--basewhite-000, #FFF);
    padding: 24px;
    bottom: 14%;
    border-radius: 20px 20px 0px 0px;
    border: 1px solid var(--coolgreycoolgrey-100, #F2F4F7);
    border-bottom: none;
    

    @media screen and (max-width: 376px) {
        bottom: 15%;
    }

    @media screen and (min-width: 377px) and (max-width: 450px) {
        bottom: 15%;
    }

    @media screen and (min-width: 451px) and (max-width: 992px) {
        bottom: 9%;
    }
}

.img40{
    width: 40px;
    height: 40px;
}

.subTopBox{
    width: 100%;
    padding: 24px;
    align-items: center;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid var(--coolgreycoolgrey-100, #F2F4F7);
}

.modalButtons{
    display: flex;
    @media screen and (max-width:992px) {
        flex-direction: column;
    }
}

.btnMobile{
    @media screen and (max-width:992px) {
        span{
            border-style: none;
            margin-bottom: 1%;
        }
    }
}

.customInputs{
    max-height: 570px;
    
    @media screen and (min-width:992px){
        overflow: scroll;
        scrollbar-width: none;
    }

    @media screen and (max-width:375px) {
        max-height: 350px;
        scrollbar-width: none;
    }

    @media screen and (min-width:995px) {
        max-height: 350px;
        scrollbar-width: none;
    }
}

.customInputsScrollable{
    @media screen and (max-height:730px) {
        overflow: scroll;
        scrollbar-width: none;
        max-height: 500px;
    }

    @media screen and (max-width:375px) and  (max-height:670px){
        overflow: scroll;
        scrollbar-width: none;
        padding-top: 5px;
        height: calc(100vh - 200px);
        overflow-y: scroll;
        scrollbar-width: none;
        scroll-behavior: smooth;
    }

    @media screen and (max-width:300px) and  (max-height:660px){
        overflow: scroll;
        scrollbar-width: none;
        height: calc(100vh - 200px);
        overflow-y: scroll;
        scrollbar-width: none;
        scroll-behavior: smooth;
    }
}

.headingFontSize{
    font-size: 26px;
    @media screen and (max-width:500px) {
        font-size: 18px;
    }
    @media screen and (min-width:501px) and (max-width:992px) {
        font-size: 24px;
    }
}

.headingImageSize{
    height: 32px;
    width: 32px;
    @media screen and (max-width:500px) {
        height: 24px;
        width: 24px;
    }
}