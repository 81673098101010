@import "../../../../styles/variables.scss";
.seprator {
    border-top: 4px solid $gray-100;
    border-bottom: 0;
    margin: 0;
}

.reason {
    display: inline-block;
    border: 1px solid $gray-300;
    padding: 6px 8px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    font-size: 13px;
    color: $gray-500;
    cursor: pointer;
    &.reasonActive {
        background-color: $red-white;
        color: $red-600;
        border: 1px solid $red-300;
        font-weight: 600;
    }
}

.icon {
    position: relative;
    top: 4px;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 58px 26px 20px 26px;
    box-shadow: 0px 0px 30px rgba(196, 209, 225, 0.3);
    @media only screen and (max-width: $xsm-screen) {
        padding: 0;
        box-shadow: none;
    }
}

.cross {
    cursor: pointer;
}

.ratingAnimation {
    width: 40px;
    height: 40px;
}

.deliveryIcon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.animWrapper {
    margin: 20px 0 26px 0;
}

.textAreaTitle {
    margin-bottom: 34px;
    @media only screen and (max-width: $xsm-screen) {
        margin-bottom: 64px;
    }
}

.submitCTA {
    position: fixed;
    bottom: 20px;
    @media only screen and (max-width: $xsm-screen) {
        left: 0;
        padding: 15px 20px 0 20px;
        width: 100%;
        bottom: 0;
        background-color: $white;
    }
}

.button {
    width: 485px;
    color: #fff;
    border-radius: 4px;
    font-size: 17px;
    font-family: "OurLexend-Medium", Helvetica, sans-serif;
    height: 48px;
    @media screen and (max-width: $xsm-screen) {
        width: 100%;
        margin-bottom: 15px;
    }
}

.rateContainer {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    .rateContainerCenter {
        margin: 20px 26px 0 26px;
        @media only screen and (max-width: $xsm-screen) {
            margin: 20px 0 0 0;
        }
    }
    .rateContainerInner {
        background: #fff;
        max-width: 540px;
        width: 100%;
        height: 100vh;
        position: fixed;
        right: 0;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        @media only screen and (max-width: $xsm-screen) {
            padding: 20px;
        }
    }
    .rateContainerBg {
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(2px);
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        min-height: 100%;
    }
    .orderDetailCard {
        box-shadow: 0px 10px 22px rgba(28, 28, 28, 0.05);
        padding: 15px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        border: 0.5px solid $gray-200;
        margin-bottom: 26px;
        @media only screen and (max-width: $xsm-screen) {
            flex-direction: column;
        }
    }
    .productCard {
        border: 1px solid $gray-200;
        border-radius: 50%;
        background: #fff;
        margin-left: -25px;
        width: 46px;
        height: 46px;
        z-index: -1;
        position: relative;
        background-size: 80% 80%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .deliveryDate {
        margin-top: 16px;
    }
    .productCard:first-child {
        margin-left: 0;
    }
    .productCardWrapper {
        display: flex;
        align-items: center;
    }
    @media screen and (max-width: $md-screen) {
        z-index: 12;
    }
}

.expandAnim {
    opacity: 0;
    animation-name: expandAnimOpac;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    textarea:focus {
        border-color: black;
    }
    textarea:focus+label {
        color: black;
    }
}

@keyframes expandAnimOpac {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}