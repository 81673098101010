@import "../../../styles/variables.scss";

.soaModalsLarge{
    > div:nth-child(2){
        width: 580px;
        height: 650px;
        border-radius: 24px;
    }
}

.downloadSOAutton{
    width: 100%;
    height: 88px;
    border-top: 1px solid var(--greygrey-200,#EBEBEB);
    position: absolute;
    bottom: 0;
    padding-left: 2rem;
    padding-right: 2rem;
}

.downloadSOARemarkBorder {
    top: 60px;
    border-radius: 16px;
    border-top: 1px solid var(--greygrey-200,#EBEBEB);
    position: relative;
}

.downloadSOARemark{
    position: relative;
    top: 18px;
    padding-left: 2rem;
    padding-right: 2rem;
}

.btnBorder{
    span{
        border-radius: 12px;
    }
}

.pd{
    padding: 2%;
}

.datePickers{
    width: 100%;
    padding-top: 20px;
}

.pills{
    width: 560px !important;
}

.pillBorder{
    width: 100%;
    border-top: 1px solid var(--greygrey-200,#EBEBEB);
    padding-right: 2rem;
    padding-left: 2rem;
}

.pdLeftRight2{
    padding-right: 2rem;
    padding-left: 2rem;
}

.notes{
    border-top: 1px solid var(--greygrey-200,#EBEBEB);
    margin-bottom: 15px;
}

.datePicker{
    width: 256px !important;
    border-radius: 32px;
}

.pdr2{
    margin-right: 20%;
}

.mrt15{
    margin-top: 15px;
}

.mbt15{
    margin-bottom: 15px;
}