@import "../../../../styles/variables.scss";

.appModeToggle {
    border: 1px solid $grey-200;
    padding: 4px;
    width: 290px;
    border-radius: 12px;
}

.appModeToggleContainer {
    border-radius: 12px;
}

.appModeToggleItem {
    width: 140px;
    height: 48px;
    border-radius: 12px;
    cursor: pointer;
}

.appModeToggleHeading {
    span {
        color: #293142;
    }
}

.appModeToggleHeadingIcon {
    width: 8px;
    height: 12px;
}

.appModeToggleSubHeading {
    font-size: 10px;
    color: $grey-600;
    text-align: center;
}

.appModeToggleItemActive {
    background: linear-gradient(94.69deg, #242F64 0%, #192045 100%);
    border: 1.5px solid;
    border-radius: 12px;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.24) 100%);
    box-shadow: 8px 2px 12px 0px #1C1C1C29;

    .appModeToggleHeading {
        span {
            color: $white;
        }
    }

    .appModeToggleSubHeading {
        color: $white;
        font-weight: 400;
    }
}

.appModeSwitchPopup {
    >div:nth-child(2) {
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        width: 400px;
        height: 300px;

        >section:nth-child(1) {
            display: flex;

            h2 {
                font-size: 24px;
                color: $sushi-gray-dark;
                font-weight: 700;
            }
        }

        >section:nth-child(2) {
            display: flex;
            justify-content: center;
        }
    }
}

.appModeSwitchPopupBody {
    width: 343px;
    height: 112px;
    color: $cool-grey-700;
    font-size: 14px;
    margin-top: 20px;
}

.appModeSwitchPopupFooter {
    justify-content: center;
    align-items: flex-end;

    span {
        padding: 20px;
        color: $red-500;
        font-weight: 500;
        border-top: 1px solid $grey-200;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }
}