@import "../../../../styles/variables.scss";
@import "../../../../styles/zIndex.scss";

.catalogCard { 
  height:100%;
  text-align: center;
  position: relative;
  padding: 0; 
  padding-top: 32px;
  border: 1px solid $gray-200;
  box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
  border-radius: 24px;
  margin-bottom: 0px;
  z-index: 1;

  .topMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    padding: 5px 10px;
    font-size: 12px;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -30px;
  }
}

.small{
  height:450px;
  text-align: center;
  position: relative;
  padding: 0; 
  padding-top: 32px;
  border: 1px solid $gray-200;
  box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
  border-radius: 24px;
  margin-bottom: 0px;
  z-index: 1;
}

.catalogCardMweb {
  border-bottom: 1px solid $border-gray;
  padding-bottom: 15px;
}

.topMessage {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px 6px 0 0;
  padding: 5px 10px;
  font-size: 12px;

  margin-left: -20px;
  margin-right: -20px;
  margin-top: -30px;
}

.topMessageNegativeMarginPromo {
  margin-left: -20px;
  margin-right: -20px;
}

.topMessageBulkDiscount {
  background: $info-bg;
  color: $teal;
}

.topMessageTrial {
  background-color: $indigo-50;
  color: $bestseller-tag-text-color;
}

.topMessagePriceDrop {
  color: $price-drop-red;
}

.mwebNudgeMessage {
  margin-top: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 10px;
  font-size: 12px;
  background: $info-bg;
  color: $teal;
  border-radius: 6px;
}

.discountTag,
.discountTagWweb {
  position: absolute;
  top: 40px;
  left: -7px;
  color: #fff;
  padding: 4px 10px;
  background-color: $pri-red;
  z-index: 1;
  border-radius: 0px 3px 3px 0px;
  font-size: 12px;
  font-weight: 500;

  .small {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 7px solid $sec-red;
    border-left: 7px solid transparent;
    position: absolute;
    bottom: -7px;
    left: 0;
  }
}

.discountTagWweb {
  position: relative;
  top: 0;
  left: -15px;
  display: inline-block;
  font-size: 11px;
  padding: 4px 6px;
  margin-bottom: 10px;

  .small {
    display: none;
  }
}

.discountTagPromo {
  width: 110px;
  height: 110px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 18px;
  position: relative;
  float: left;

  @media only screen and (max-width: $xs-screen) {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
    z-index: -1;
  }

  img.light {
    right: -3px;
    bottom: -3px;
    z-index: -1;
  }
}

.discountTagPromoProduct {
  float: left;
  display: inline-block;
  width: calc(100% - 125px);
  height: 120px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;

  @media only screen and (max-width: $xs-screen) {
    width: calc(100% - 90px);
  }
}

.clearFix {
  clear: both;
}

.catalogFooter {
  border-top: 1px solid $sushi-gray-border;
  overflow: hidden;
}

.grayWrap {
  border-left: 1px solid $sushi-gray-border;
  border-bottom: 1px solid $sushi-gray-border;
  border-radius: 0 0 0 6px;
}

.deliveryTruckIcon {
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

.redBox {
  background-color: $tag-red-light;
  border-radius: 4px;
  text-decoration: line-through;
  padding: 10px 15px;
  color: $red;
  position: relative;
  margin-bottom: 10px;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    margin: auto;
    left: 0;
    right: 0;
    border-top: solid 8px $tag-red-light;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }
}

.tag {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: $z-card-tag;
  border-radius: 4px 0px 0px 4px;
  font-size: 12px;
  padding: 1px 8px;
  color: $bestseller-tag-text-color;
}

.tagV2 {
  margin-top:10px;
  margin-left:15px;
  border: solid 1px;
  border-radius:8px;
  align-items: center;
  padding: 4px 8px;
  gap: 6px;
  width:max-content;
}

.imgTag {
  height:15px;
  width:15px;
  background: linear-gradient(90deg, #E5F3F3 0%, rgba(229, 243, 243, 0) 100%);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.offerTag {
  border-radius: 4px;
  padding: 1px 8px;
  width: fit-content;
}

.offerImg {
  width: 20px;
  height: 20px;
}

.offerV2 {
  border-radius: 8px;
  padding: 16px;
  gap: 12px;
}

.offerLine {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: $blue-100;
  margin-bottom: 12px;
  margin-top: 12px;
}

.tickImage {
  height:20px;
  width:20px;
}

.imageContainer {
  width: 120px;
  height: 120px;
  margin-left:auto;
  margin-right: auto;
}

.myListContainer {
  position: absolute;
  right: 0;
  top: 110px;
  margin-right: 12.5px;
}

.mwebTag {
  position: relative;
  left: -15px;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 10px;
  padding: 3px 5px;
  color: $bestseller-tag-text-color;
  background: rgba($color: $bestseller-tag-background-color, $alpha: 0.4);
  z-index: 1;
}

.launchingSoon {
  align-items: center;
  display: flex;
  padding: 4px 6px;
  background: #fbfbfb;
  border: 0.5px solid #9c9c9c;
  border-radius: 6px;
  font-family: "OurLexend-Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #9c9c9c;
  margin-top: 4px;
}


.addBtn {
  width: 220px;
}

.space {
  min-height: 2.3vh;
  margin-top: 5px;
  margin-bottom:3px;
}

.headerCap {
  height:60px;
  padding: 10px 10px 30px;
  position:relative;
  top: 20px;
  border: 1px solid $white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  margin-top:-20px;
}

.blankHeader {
  height:60px;
  padding: 10px 10px 30px;
  position:relative;
  top: 20px;
  background-color: inherit;
  border: 1px solid transparent;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  margin-top:-20px;
}

.height18{
  height:18px;
}

.fsResp{
  font-size: 14px;
  @media screen and (max-width: 1200px) {
        font-size: 12px;
    }
  @media screen and (max-width: 1000px) {
      font-size: 12px;
  }
}

.price {
  font-size:18px;
  @media screen and (max-width:1200px) {
    font-size: 12px;
  }
}

.comparePrice {
  font-size:14px;
  @media screen and (max-width:1200px) {
    font-size: 11px;
  }
}

.subPrice {
  font-size:13px;
  @media screen and (max-width:1200px) {
    font-size: 11px;
  }
}

.truncatedPrice {
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
}

.m1{
  margin-top:4px;
}

.borderOffer{
  border: 1px solid #EDF4FF;
}

.bottomCap {
  height:62px;
  padding: 30px 10px 12px;
  background-color: #E8EAF5;
  position:relative;
  bottom: 20px;
  border: 1px solid $white;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-bottom:-20px;
}

.blankFooter {
  height:62px;
  padding: 30px 10px 12px;
  position:relative;
  bottom: 20px;
  background-color: inherit;
  border: 1px solid transparent;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  margin-bottom:-20px;
}

.bottomCTA {
  position: relative;
  bottom:10px;
  text-align: center;
}

.bottomCTAResp {
  text-align: center;
  @media screen and (max-width:1200px) {
    position: relative;
    bottom:10px;
    text-align: center;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3;
  -webkit-box-orient: vertical;
}

.negMargin {
  margin-top:-25px;
}

.negMarginMulti {
  margin-top:-200px;
}

.emptyTag {
  height:35px;
}

.dropdownTriangleOffersV2 {
  height: 0px;
  width: 0px;
  border-top: 2px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: relative;
  left: 13px;
  z-index: 3;
}

.marginBottom24px{
  margin-bottom: 24px;
}

.img16{
  width: 16px;
  height: 16px;
}

.offerTag{
  width: fit-content;
    padding: 4px 12px 4px 12px;
    border-radius: 8px 8px 0px 0px;
}
