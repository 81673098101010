.blackToast{
    background-color: #1c1c1c !important;
    font-size: 16px;
    color: white !important;
    position: fixed !important;
    bottom: 50px !important;
    i{
        background-color: #4f4f4f4f;
        padding: 7px;
        border-radius: 50%;
    } 
    img {
        height: 32px;
        width: 32px;
    }
}
