// imports
@import "../../styles/variables.scss";

// ** pos outlet selection modal css ** //
.posOutletSelectionModal {
  >div:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  >div:nth-child(2) {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    width: 540px;
    height: 800px;
    background-color: $indigo-cool-50;

    >section:nth-child(1) {
      display: flex;
      margin-bottom: 2.2rem;

      h2 {
        font-size: 30px;
        color: $sushi-gray-dark;
        font-weight: 700;
      }
    }

    >section:nth-child(2) {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      overflow-y: auto;
    }

    @media only screen and (max-width: 768px) {
      width: 100vw;
      height: 100%;
      border-radius: 0;
    }
  }

  .searchableInput {
    img {
      height: 16px;
      width: 16px;
      margin-left: 8px;
    }

    label {
      margin-left: 10px;
      top: 10px;
    }

    input {
      border-radius: 100px !important;
      padding-left: 40px !important;
      border: 1px solid $grey-200;
    }

    input:focus {
      border: 2px solid $red-500;
    }
  }

  .noOutletInfo {
    border: 1px solid $grey-200;
    padding: 20px;
    border-radius: 20px;
  }

  .posAccountName {
    border-bottom: 1px solid $grey-200;
  }

  .inactiveTag {
    background: #ffedef;
    border-radius: 6px;
    padding: 4px 8px;
    color: $red-700;
    width: fit-content;
  }

  .posOutletListContainer {
    box-shadow: 0px 1px 4px 0px #1c1c1c0f;
    border-radius: 20px;
    padding: 24px;
  }

  .dashedLine {
    height: 1px;
    width: 100%;
    border: 1.5px dashed $cool-gray-100;
  }

  .posLinkContainer {
    margin-top: -12px;
  }

  .posLinkIcon {
    padding: 12px;
    border-radius: 48px;
    border: 12px solid $indigo-cool-50;
  }

  .posOutletsContainer {
    margin-top: -12px;

    >section:nth-child(1) {
      >div:nth-child(1) {
        border-radius: 24px;

        >div>section>h5 {
          font-size: 22px;
          color: #293142;
          font-weight: 700;
        }
      }
    }

    .posOutletContainer {
      >section:nth-child(2) {
        margin: 0;

        label {
          margin: 0;

          span {
            display: none;
          }
        }
      }
    }
  }

  .posOutletSelectionModalFooter {
    border-radius: 0px 0px 24px 24px;
  }

  button {
    width: 100%;

    span span {
      font-family: "OurLexend-Regular", Helvetica, sans-serif !important;
    }

    >span:nth-child(1) {
      border-radius: 12px;
    }
  }

  @media only screen and (max-width: 768px) {
    z-index: 1010 !important;
    position: fixed !important;
  }
}

.orderReceiverUpdateModal {
  >div:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  >div:nth-child(2) {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    width: 540px;
    height: 800px;
    background-color: $indigo-cool-50;

    >section:nth-child(1) {
      display: flex;
      margin-bottom: 2.2rem;

      h2 {
        font-size: 30px;
        color: $sushi-gray-dark;
        font-weight: 700;
      }

      i {
        margin-top: -24px;

        svg {
          height: 32px;
          width: 32px;
        }
      }
    }

    >section:nth-child(2) {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      overflow-y: auto;
    }
  }

  .outletSelectionModalFooter {
    border-radius: 0px 0px 24px 24px;

    label {
      color: $gray-800 !important;
    }
  }

  button {
    width: 100%;

    span span {
      font-family: "OurLexend-Regular", Helvetica, sans-serif !important;
    }

    >span:nth-child(1) {
      border-radius: 12px;
    }
  }

  .receiverModalBox {
    border-radius: 20px;

    svg {
      height: 24px;
      width: 24px;
      color: $grey-500;
    }

    img {
      height: 44px;
      width: 44px;
    }
  }

  .existingReceiverModalBox {
    border-radius: 20px;
    border: 1px solid $grey-200;
  }

  .existingReceiverModalBoxActive {
    border: 1px solid $cool-grey-700;
  }

  .contentDivider {
    color: $indigo-500;
    opacity: 0.4;

    >span:nth-child(1) {
      border: 1px solid $indigo-500;
      width: 24px;
      border-radius: 12px;
      opacity: 0.4;
    }

    >span:nth-child(3) {
      border: 1px solid $indigo-500;
      width: 24px;
      border-radius: 12px;
      opacity: 0.4;
    }
  }

  @media screen and (max-width: $md-screen) {
    >div:nth-child(2) {
      margin-bottom: 0;
      width: 100vw;
      max-height: 400px;

      >section:nth-child(1) {
        display: flex;
        margin-bottom: 0;

        h2 {
          font-size: 20px;
        }

        i {
          margin-top: -16px;

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }

    }

    .outletSelectionModalFooter {
      border-radius: 0px;
    }

    .receiverModalBox {
      padding: 16px;

      svg {
        height: 16px;
        width: 16px;
      }

      img {
        height: 32px;
        width: 32px;
      }

      >div:nth-child(1) {
        >div:nth-child(1) {
          font-size: 18px;
        }

        >span:nth-child(1) {
          font-size: 18px;
        }
      }
    }

    .contentDividerContainer {
      padding: 0px;
      margin-bottom: 20px;
    }

    .existingReceiverModalBox {
      padding: 8px;
      border-radius: 16px;

      .receiverNameText {
        font-size: 13px;
      }

      .receiverPhoneText {
        font-size: 14px;
      }
    }

  }
}

.addOrderReceiverModal {
  >div:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  >div:nth-child(2) {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    width: 480px;
    height: 540px;
    background-color: $indigo-cool-50;

    >section:nth-child(1) {
      display: flex;
      margin-bottom: 2.2rem;
      position: relative;

      h2 {
        font-size: 30px;
        color: $sushi-gray-dark;
        font-weight: 700;
        max-width: 400px;
      }

      i {
        position: absolute;
        top: 0px;
        right: 0px;

        svg {
          height: 32px;
          width: 32px;
        }
      }
    }

    >section:nth-child(2) {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      overflow-y: auto;
    }
  }

  .modalFooter {
    border-radius: 0px 0px 24px 24px;

    label {
      color: $gray-800 !important;
    }

    input {
      width: 20px;
    }
  }

  button {
    width: 100%;

    span span {
      font-family: "OurLexend-Regular", Helvetica, sans-serif !important;
    }

    >span:nth-child(1) {
      border-radius: 12px;
    }
  }

  .modalBodyPhoneInput {
    input {
      border-radius: 12px;
      min-height: 56px;
      box-shadow: 0px 1px 4px 0px #1c1c1c0f;
      padding-left: 80px;
    }
  }

  .modalBodyInput {
    input {
      border-radius: 12px;
      min-height: 56px;
      box-shadow: 0px 1px 4px 0px #1c1c1c0f;
    }
  }

  .inputPhoneLeftItem {
    z-index: 100;
    top: 18px;
    left: 12px;

    img {
      height: 20px;
      width: 30px;
    }
  }

  @media screen and (max-width: $md-screen) {
    >div:nth-child(2) {
      margin-bottom: 0;
      width: 100vw;
      max-height: 400px;
      border-radius: 24px 24px 0px 0px;

      >section:nth-child(1) {
        display: flex;
        margin-bottom: 0;

        h2 {
          font-size: 20px;

          span {
            >span:nth-child(2) {
              font-size: 14px;
              line-height: 2rem;
            }
          }
        }

        i {

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }

    }

    .modalFooter {
      border-radius: 0px;
    }

    .receiverModalBox {
      svg {
        height: 16px;
        width: 16px;
      }

      img {
        height: 32px;
        width: 32px;
      }
    }

    .contentDividerContainer {
      padding: 8px 0;
    }
  }
}

.digitalPodInfoModal {
  >div:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.7);
  }

  >div:nth-child(2) {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    width: 480px;
    max-height: 650px;
    background-color: $indigo-cool-50;

    >section:nth-child(1) {
      display: flex;
      justify-content: center;
      margin-bottom: 0;

      h2 {
        font-size: 26px;
        color: $sushi-gray-dark;
        font-weight: 700;
      }

      i {
        margin-top: -24px;

        svg {
          height: 32px;
          width: 32px;
        }
      }
    }

    >section:nth-child(2) {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      overflow-y: auto;
    }
  }

  .digitalPodModalFooter {
    border-radius: 0px 0px 24px 24px;

    label {
      color: $gray-800 !important;
    }
  }

  button {
    width: 100%;

    span span {
      font-family: "OurLexend-Regular", Helvetica, sans-serif !important;
    }

    >span:nth-child(1) {
      border-radius: 12px;
    }
  }

  .digitalPodInfoSection {

    img {
      height: 100px;
      width: 100px;
    }

    width: 100%;
  }

  .receiverSection {
    border: 1px solid $grey-200;
    border-radius: 24px;

    img {
      height: 44px;
      width: 44px;
    }

    .rightCTAIcon {
      height: 24px;
      width: 24px;
    }
  }
}