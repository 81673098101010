@import "../../../styles/variables.scss";

.modalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 380px;
  height: 210px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid $gray-200;
}

.animation {
  text-align: center;
  width: 100px;
  height: 100px;
}
