@import "../../../styles/variables.scss";
@import "../../../styles/zIndex.scss";

.imageContainer {
  position: relative;
  min-height: 90px;
  min-width: 117px;
  width: 117px;
  height: 90px;
  top:30px;

  @media (max-width: $md-screen) {
    position: initial;
  }
}

.isseModalImgContainer{
  @media (max-width: $md-screen) {
    display: flex;
    justify-content: center;
  }
}

.deliveryTruckIcon {
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

.offerImg {
  width: 20px;
  height: 20px;
}

.offerTag {
  border-radius: 4px;
  padding: 1px 8px;
  width: fit-content;
}

.redBox {
  background-color: $tag-red-light;
  border-radius: 4px;
  text-decoration: line-through;
  padding: 10px 15px;
  color: $red;
  position: relative;
  margin-bottom: 10px;
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    margin: auto;
    left: 0;
    right: 0;
    border-top: solid 8px $tag-red-light;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }
}

.nudgeMessage {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 10px;
  font-size: 12px;
  background: $info-bg;
  color: $teal;
  border-radius: 6px;
  margin-top: 10px;
  width: 100%;
}

.header {
  padding: 24px 24px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 5px);
  background-color: #F4F6FB;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  .headerTitle {
    font-size: 24px;

    .exclamationIcon {
      position: relative;
      top: 1px;
    }

    @media (max-width: $md-screen) {
      font-size: 18px;
    }
  }

  .headerSubtitle {
    font-size: 20px;
    color: $info-color;
  }
}

.modalBody {
  max-height:750px;
  height:max-content;
  max-width:630px;
  padding: 0px;
  overflow-y: scroll;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius:24px;

  @media (max-width: $md-screen) {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.detailContainer {
  width: 100%;
  padding:20px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid $gray-200;
  border-radius: 24px;
  background-color: white;
  @media (max-width: $md-screen) {
    display: flex;
    flex-direction: column;
  }
}

.railHeading {
  padding-left: 10px;
  margin-bottom: 6px;
}

.alternateProductsSection {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.75s;
  background-color: white;
  padding-left:14px;
}

.paddingFix {
  padding: 32px 32px 0 32px;
  font-size: 0;
  background-color: #F4F6FB;
}

.issueDescription {
  padding-left: 60px;
  background-color: #F4F6FB;
  padding-top:6px;
  padding-left:70px;

  @media (max-width: $md-screen) {
    min-width: 80vw;
  }
  
}

.cta {
  margin-right:25px;
  margin-left: auto;
}

.offerV2 {
  border-radius: 8px;
  padding: 12px;
  gap: 12px;
}

.offerLine {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: $blue-100;
  margin-bottom: 6px;
  margin-top: 10px;
}

.tickImage {
  height:20px;
  width:20px;
}

.tag {
  position: absolute;
  top: 16px;
  right: 0;
  z-index: $z-card-tag;
  border-radius: 4px 3px 0px 4px;
  font-size: 12px;
  padding: 1px 8px;
  color: $bestseller-tag-text-color;
}

.tagV2 {
  margin-top:10px;
  margin-left:15px;
  border: solid 1px;
  border-radius:8px;
  align-items: center;
  padding: 4px 8px;
  gap: 6px;
  width:max-content;
}

.imgTag {
  height:15px;
  width:15px;
  background: linear-gradient(90deg, #E5F3F3 0%, rgba(229, 243, 243, 0) 100%);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.issueModalContainer {
  div:nth-child(2) {
    border-radius: 24px;
  }

  >div:nth-child(2){
    @media (max-width: $md-screen) {
      min-height: initial;
      max-width: 100dvw;
      position: fixed;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

}

.exclamation {
  height:27px;
  width:27px;
  position: relative;
  top:5px;
}

.img16{
  height: 16px;
  width: 16px;
}

.offerTagV2 {
  border-radius: 4px 4px 0px 0px;
  padding: 2px 8px;
}

.buttonMobile{
  width: 100%;
  margin-bottom: 32px;
}