@import "../../../../styles/variables.scss";

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.1s ease-out, backdrop-filter 0.1s ease-out;
  z-index: 10;
  backdrop-filter: none;
  transition-delay: 0.12s;

  &.visible {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
  }
}

.panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  transform: translateX(100%);
  transition: transform 0.2s ease-out;
  z-index: 11;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;

  &.visible {
    transform: translateX(0%);
  }

  .heading {
    padding: 18px 20px;
    font-size: 22px;
    font-weight: 600;
    border-bottom: 1px solid $sushi-gray-border;
  }

  .infiniteScrollNegativeMargin {
    display: flex;
    flex-direction: column;
    margin-top: -20px;
  }

  .notification {
    padding: 18px 20px;
    border-bottom: 1px solid $sushi-gray-border;
    cursor: pointer;

    &:hover {
      background-color: darken(white, 5%);
    }

    &.unread {
      background-color: $delivery-tag-blue-background;

      &:hover {
        background-color: darken($delivery-tag-blue-background, 5%);
      }
    }

    .image {
      height: 50px;
      width: 50px;
    }
  }
}

@media screen and (min-width: $sm-screen) {
  .panel {
    width: 500px;
  }
}

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  align-items: center;
  z-index: 1000;
  justify-content: center;
  font-size: 18px;
  flex-direction: column;
}
