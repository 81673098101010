// Screen Sizes
$xs-screen: 420px;
$xsm-screen: 540px;
$sm-screen: 768px;
$md-screen: 992px;
$lg-screen: 1200px;

$container-max-screen: 1200px;

// Colors

// green
$pri-green: #50b547;
$sec-green: #28a745;
$tri-green: #67c54f;
$light-green: #e7f5ea;
$dark-green: #3ab757;

// link color
$pri-orange: #ff7900;
$sec-orange: #ff9a3f;

$red: #f53a3a;
$red-500: #ef4f5f;
$red-600: #e03546;
$red-700: #bf2938;
$red-300: #ffb1c0;
$red-400: #ff7e8b;

$pri-blue: #3f7aff;
$sec-blue: #41a0bd;
$blue-50: #F7FAFF;
$blue-200: #DBE8FF;
$blue-700: #1148a6;
$blue-500: #256fef;

$pri-yellow: #fceec0;
$sec-yellow: #dc9d09;
$yellow-600: #e9b501;

$pri-black: #1c1c1c;

$gray-wrap: #f8f8f8;



// gray
$gray-dark: #727272;
$gray-med: #9d9d9d;
$gray-light: #e2e2e2;
$gray-lightest: #f2f2f2;
$gray-card-background: #fbfbfb;
$gray-5: #555;
$shadow-gray: #c9c9c9;
$border-gray: #e9ebef;
$gray-border-light : #f2f4f7;
$gray-modal-bg : #F4F6FB;

// red
$pri-red: #ec4654;
$sec-red: #d02a38;
$red-border: #e03546;

$sushi-gray: #cfcfcf;
$sushi-gray-light: #696969;
$sushi-gray-med: #b5b5b5;
$sushi-gray-dark: #1c1c1c;
$sushi-gray-text: #828282;
$sushi-light-green: #8adc82;
$sushi-green: #8adc82;
$sushi-gray-border: #e8e8e8;
$sushi-green-highlight: #01b460;
$sushi-blue: #ddecfd;
$sushi-blue-dark: #0366d6;
$sushi-red: #ed5a6b;
$gray-900: #363636;
$gray-800: #4f4f4f;
$gray-700: #696969;
$gray-500: #9c9c9c;
$gray-200: #ebebeb;
$gray-300: #d6d6d6;
$gray-100: #f5f5f5;
$cool-gray-100: #F2F4F7;
$filter-btn-gray: #f4f4f4;
$grey-500:#9197A6;

$blue-bg: #e4eefa;
$blue-color: #2781e7;
$blue-dark: #023774;
$blue-color-bg: rgba(39, 129, 231, 0.07);
$blue-100: #edf4ff;

$red-smoke: #fbf6f7;

$light-counter-bg: #f5faf6;

$info-color: #12a2ab;
$info-bg: #e5f3f3;
$info-blue: #f7f9fd;

$box-shodow-light: 0 0 8px #e2e2e2;
$box-shadow-spread: 0px 0px 60px rgba(196, 209, 225, 0.5);
$box-shadow-spread-light: 0px 0px 15px rgba(196, 209, 225, 0.5);
$box-shadow-spread-lightest: 0px 5px 22px rgba(28, 28, 28, 0.02);
$box-shadow-spread-highlight: 0px 1px 2px rgba(54, 54, 54, 0.06);
$box-shadow-spread-highlight-2: 0px 0px 15px rgba(0, 0, 0, 0.08);
$box-shadow-spread-card: 0px 10px 15px rgba(28, 28, 28, 0.03);

$box-shadow-card: 0px 10px 22px rgba(28, 28, 28, 0.05);
$box-shadow-card-light: 0px 10px 70px rgba(228, 233, 235, 0.2);
$box-shadow-card-med: 0px 10px 22px rgba(228, 233, 235, 0.4);
$box-shadow-card-hover: 0px 14px 22px rgba(28, 28, 28, 0.1);

$facebook-icon-color: #3b5998;
$twitter-icon-color: #55acee;
$delivery-tag-blue: #539cee;
$delivery-tag-blue-background: #f6f9fd;

$teal: #119199;
$teal-sec: #7acdcd;
$teal-400: #12a2ab;
$teal-white: #f6fcfc;
$teal-border: #b6dee0;
$teal-800: #0A757C;

$tag-red: #f76b6c;
$tag-red-light: #f7ecec;
$tag-gray: #989898;
$tag-white: #f6fcfc;

$rating-red: #d41120;
$notification-red: #e23744;
$price-drop-red: #f57082;
$red-border: #e03546;

$yellow100: #fefaec;
$red100: #f7ebec;

$indigo-400: #3c4886;
$indigo-50: #f8f8fb;
$indigo-cool-50: #F4F6FB;


$bestseller-tag-text-color: #717dba;
$bestseller-tag-background-color: #b3bada;

$app-bg: #203a4f;

$purple-900: #27134e;
$purple-500: #6c42c1;

$indigo-500: #2c3875;

$grey-400: #b8b8b8;
$grey-600: #767C8F;
$cool-grey-400: #AFB4C0;
$cool-grey-600: #767C8F;
$cool-grey-700: #596378;

$red-white: #fff5f6;
$red-100: #ffedef;
$red-number: #ffdbe0;

$border-light: #e7e7e7;

$orange-bg: #fff1e6;
$orange-border: #f9d0a9;
$orange-text: #e86c37;
$green-color-verified :#24963F;
$white: #ffffff;
$grey-200 :#E6E9EF;
$grey-100 : #E8EAF5;

$sm-screen-text-from-left: 11%;
$md-screen-text-from-left: 8%;
$lg-screen-text-from-left: 6%;


$sm-screen-banner-text-from-left: 7%;
$md-screen-banner-text-from-left: 7%;
$lg-screen-banner-text-from-left: 7%;