@import "../../../styles/variables.scss";

.content {
  width: 100%;
  padding: 25px 20px 24px 24px;
  font-size: 0;
  border-top: 1px solid $gray-light;
}

.paymentBreakup {
  border-radius: 6px;
  border: 1px solid $gray-200;
  font-size: 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;
}

.walletBalance {
  padding-left: 30px;
}

.finalAmount {
  background-color: #f7faff;
  border-radius: 0 0 6px 6px;
}

.submitBtn {
  width: 100%;
  background-color: $red-600;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  height: 48px;
  margin-top: 24px;
  > span {
    justify-content: space-between;
    padding: 0 32px;
  }
}

.borderBtm {
  border-bottom: 1px solid $gray-light;
}

.dueDetails {
  border: 1px solid $gray-200;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border-radius: 6px;
}

.paymentFailure {
  border: 1px solid $gray-200;
  padding: 18px 16px 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  border-radius: 6px;
}

.failIcon {
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 9px;
}

.cashIcon {
  width: 24px;
  height: 24px;
  background-size: cover;
  margin-right: 12px;
}

.onlineIcon {
  width: 24px;
  height: 24px;
  background-size: cover;
  margin-right: 12px;
}

.PayOnDeliveryModal {
  > div:nth-child(2) {
    width: 49rem;
    @media screen and (max-width: $xsm-screen) {
      width: 35rem;
    }
  }
}

