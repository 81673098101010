@import "../../../styles/variables.scss";

.paddle {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    top: calc(50% + 24px);
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 9.6px 9.6px rgba(54, 54, 54, 0.08),
      0px 19.2px 19.2px rgba(54, 54, 54, 0.06);
    //top: 40% !important;
    cursor: pointer;
}

.scLeft {
  left: -10px !important;
}

.scRight {
  right: -10px !important;
}


.bannerLeft{
  left: -17px !important;
}

.bannerRight {
  right: -17px !important;
}

.aerobarLeft{
  left: -17px !important;
  top: calc(25% + 24px) !important;
}

.aerobarRight {
  right: -17px !important;
  top: calc(25% + 24px) !important;
}


.testimonialLeft{
  left: -17px !important;
  top: calc(50%) !important;
}

.testimonialRight {
  right: -17px !important;
  top: calc(50%) !important;
}

.discountLeft{
  left: -22px !important;
  top: calc(50%) !important;
}

.discountRight {
  right: -22px !important;
  top: calc(50%) !important;
}
