@import "../../../styles/variables.scss";

.react-datepicker {
    width: 100%;
    border-radius: 24px !important;
}

.react-datepicker__header {
    background-color: white !important;
    height: 75px;
    border-bottom: none !important;
    border-radius: 24px !important;
}

.react-datepicker__month-container {
    width: 100%;
    height: 100%;
}

.react-datepicker__month {
    height: 200px;
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
    font-size: 14px;
    padding-top: 24px;
}

.react-datepicker__day-name {
    color: $grey-500 !important;
}

.react-datepicker__week {
    display: flex;
    justify-content: space-around;
    height: 20%;
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
    font-size: 16px;
}

.react-datepicker__current-month {
    visibility: hidden;
}

.react-datepicker__header__dropdown {
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 16px;
}

.react-datepicker__day--selected {
    color: white !important;
    background-color: $red-500 !important;
}

.react-datepicker__day {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px !important;
    border-radius: 50% !important;
}

.react-datepicker__day:hover {
    color: $red-700 !important;
    background-color: $red-white !important;
}

.react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none;
}

.react-datepicker__navigation {
    visibility: hidden;
}

.react-datepicker__month-read-view--down-arrow {
    border-color: $red-500 !important;
    top: 3px !important;
    right: 0px !important;
    border-radius: 1px !important;
}

.react-datepicker__year-read-view--down-arrow {
    border-color: $red-500 !important;
    top: 3px !important;
    right: 20px !important;
    border-radius: 1px !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: white !important;
}

.react-datepicker__day--today {
    font-weight: normal !important;
}

.react-datepicker__month-dropdown {
    width: 200px !important;
    border-radius: 16px;
    position: absolute !important;
    top: 0 !important;
    left: 11px !important;
    height: 280px;
    overflow-y: auto;
}

.react-datepicker__navigation--years {
    height: 16px !important;
    color: $grey-500 !important;
    visibility: visible !important;
}

.react-datepicker__year-dropdown {
    width: 150px !important;
    border-radius: 16px;
    position: absolute !important;
    top: 0 !important;
    left: 250px !important;
    overflow-x: hidden;
    min-height: 280px;
}

.react-datepicker__navigation--years-previous {
    top: 0px;
}

.react-datepicker__navigation--years-previous::before {
    content: "▼" !important;
    font-size: 10px;
    color: #9197A6;
    display: block;
    text-align: center;
    text-indent: 0;
}

.react-datepicker__navigation--years-upcoming::before {
    content: "▲" !important;
    font-size: 10px;
    color: #9197A6;
    display: block;
    text-align: center;
    text-indent: 0;
}