@import "../../../styles/variables.scss";
@import "../../../styles/zIndex.scss";
.headerWrapper {
    width: 100vw;
    padding: 10px 0px;
    border-bottom: 0.5px solid $sushi-gray-border;
    position: fixed;
    height: 88px;
    left: 0;
    top: 0;
    z-index: $z-header-wrap;
    background: #fff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 40px 40px;
    @media only screen and (max-width: $md-screen) {
      box-shadow: 0px 0px 30px rgba(196, 209, 225, 0.3);
      border-bottom: 0px solid $sushi-gray-border;
      height: 80px;
    }
  
    @media only screen and (max-width: $md-screen) {
      z-index: 10;
      box-shadow: none;
    }
    .navItem {
      margin-right: 1.5rem;
    }
  }
  
  .staticHeader {
    position: absolute;
    display: inline-block;
    background: rgba(0, 0, 0, 0);
    border-bottom: 0 solid $sushi-gray-border;
    box-shadow: none !important;
    // background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1));
    &:before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  
  .transparentBackground {
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    border-bottom: none;
  }
  
  .staticHeaderWithAppPrompt {
    position: absolute;
    display: inline-block;
    background: rgba(0, 0, 0, 0);
    border-bottom: 0 solid $sushi-gray-border;
    box-shadow: none !important;
    // background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1));
    &:before {
      content: "";
      top: 59px;
      left: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 55%);
    }
  }
  
  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    transition:
      background-color 0.1s ease-out,
      backdrop-filter 0.1s ease-out;
    z-index: 10;
    backdrop-filter: none;
    transition-delay: 0.12s;
    &.visible {
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(3px);
    }
  }
  
  .panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    height: 100vh;
    transform: translateX(100%);
    transition: transform 0.2s ease-out;
    z-index: 11;
    background-color: #f2f4f7;
    overflow-y: auto;
    overflow-x: hidden;
    &.visible {
      transform: translateX(0%);
    }
  }
  
  @media (max-width: 992px) {
    .panel {
      width: 100%;
    }
  }
  
  .bottomHeader {
    position: fixed;
    background: #fff;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 9;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: inset 0px 0.3px 0px #e8e8e8;
  }
  
  .siteMessage {
    margin-top: 66px;
    margin-bottom: -75px;
    position: relative;
    text-align: center;
    background: #fdc9d1;
    font-size: 15px;
    padding: 10px;
    @media only screen and (max-width: 992px) {
      margin-top: 108px;
      margin-bottom: -112px;
    }
  }
  
  @keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.3);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .cartLinkAnimation {
    animation-name: scaleAnimation;
    animation-duration: 1s;
  }
  
  .cartCountContainer {
    position: absolute;
    top: -6px;
    right: -24px;
    padding: 12px;
    background: $red-white;
    color: $red-border;
    font-size: 11px;
    border-radius: 50%;
    .cartCount {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
    }
  }
  
  .icon {
    position: relative;
    top: 2px;
  }
  
  .headerLogo {
    width: fit-content;
    margin-left: 20px;
    cursor: pointer;
  
    @media only screen and (max-width: 992px) {
      width: 60%;
    }
  }
  
  .unseenNotificationsCount {
    background: #ef4f5f;
    position: absolute;
    top: -1px;
    right: 1px;
    padding: 3px;
    border-radius: 7px;
    width: 12px;
    height: 12px;
    min-width: 10px;
    text-align: center;
    border: 1px solid white;
    & > div {
      color: white;
      font-size: 12px;
      line-height: 10px;
    }
  }
  
  .nativeAppPrompt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 10px 20px;
    margin-top: -10px;
    margin-bottom: 10px;
    background: $light-green;
    box-shadow: inset 0px -2px 8px 0px #dddddd;
    .closeIcon {
      position: relative;
      top: 4px;
    }
    a {
      display: inline-block;
      padding: 10px 15px;
      color: white;
      background: $sec-green;
      border-radius: 5px;
      font-weight: 600;
      box-shadow: 2px 2px 4px 0px #bbbbbb;
    }
  }
  
  .downloadDropdown {
    div {
      width: 100%;
    }
  }
  
  .modalWrapper {
    width: 380px;
    height: 229px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    z-index: 1000;
  }
  
  .animation {
    width: 60px;
    height: 60px;
    margin: 50px auto 30px;
  }
  
  .oosText {
    margin: 30px 48px 0;
    padding: 0px 10px;
    text-align: center;
  }
  
  .greyBorderLine {
    border-top: 1px solid #ebebeb;
  }
  
  .waiter {
    width: 30px;
    height: 30px;
  }
  
  .logoImg {
    width: 150px;
    height: 42px;
  }
  
  .daModal {
    padding: 24px;
    width: 440px;
  }
  
  .paddingTop {
    margin-top: 45px;
  }
  
  .gray700 {
    color: $sushi-gray-light;
  }
  
  .btnWidth {
    width: 100%;
    span {
      font-weight: 600;
      font-family: "OurLexend-SemiBold", Helvetica, sans-serif;
    }
  }
  
  .btnBorder {
    span {
      border-radius: 12px;
    }
  }
  
  .maskedNumberCTA {
    border: 1.5px solid #ebebeb;
    background: #fbfbfb;
    border-radius: 12px;
    padding: 16px 80px 16px 80px;
    width: 100%;
    display: flex;
  }
  
  .loginInput {
    i {
      display: none !important;
    }
    div {
      div {
        pointer-events: none;
      }
    }
  }
  
  .deliveryHeader {
    margin-right: 10px;
    width: 25%;
    min-width: 320px;
    @media (max-width: 1200px) {
      min-width: 260px;
    }
  
    @media (max-width: 992px) {
      min-width: 240px;
    }
  
    @media (max-width: 860px) {
      min-width: 200px;
    }
    @media (max-width: 500px) {
      min-width: 0px;
      display: none;
    }
  }
  
  .pdLeft20px {
    padding-left: 20px;
  }
  
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .truncateName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .grey900 {
    color: $gray-900;
  }
  
  .grey600 {
    color: $sushi-gray-text;
  }
  
  .img24 {
    height: 24px;
    width: 24px;
  }
  
  .imgSideBar {
    width: 16px;
    height: 14px;
  }
  
  .iconBox {
    border: 1px solid #e6e9ef;
    border-radius: 100px;
    padding: 12px;
  }
  
  .cartItem {
    border: 1px solid #e6e9ef;
    border-radius: 100px;
    padding: 12px;
  }
  
  .blackCart {
    background-color: #ef4f5f;
  }
  
  .padding24Xaxis {
    margin-left: 20px;
    margin-right: 20px;
  
    @media (max-width: 992px) {
      margin-left: 16px;
      margin-right: 12px;
    }
  }
  
  .wdFillAvailable {
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    width: -moz-available;
  }
  
  .cartProductCount {
    background-color: black;
    color: white;
    padding: 1px 6px;
    border-radius: 100px;
    position: absolute;
    top: -1px;
    right: -5px;
    border: 2px white solid;
  }
  
  .pdIconRight {
    margin-right: 20px;
  
    @media (max-width: 992px) {
      margin-right: 16px;
    }
  }
  
  .fixedSubHeader {
    position: absolute;
    bottom: -43px;
    left: 190px;
    border-radius: 0px 0px 16px 16px;
    padding: 12px 20px;
    color: white;
  }
  
  .subHeadingPassMatch {
    flex-wrap: wrap;
    background: var(--COOL-GREY-COOL-GREY50, #f8f9fc);
    border-radius: 12px;
    border: 1px solid var(--COOL-GREY-COOL-GREY200, #e4e9f1);
    padding: 12px 16px;
  }
  
  .wdMinSave {
    min-width: 208px;
  }
  
  .wdMinSaveWithMyList {
    min-width: 262px;
  }
  
  .headerTime {
    .headerPopup {
      position: absolute;
      top: 54px;
      background: #293142;
      border-radius: 16px;
      width: 280px;
      padding: 18px;
      color: white;
      display: none;
      text-align: center;
      z-index: $z-index-delivery-header-popup;
    }
    .headerPopup:hover {
      display: block;
    }
  }
  
  .headerTime:hover {
    .headerPopup {
      display: block;
    }
  }
  
  .upTriangleOffersV2 {
    height: 0px;
    width: 0px;
    border-top: 2px solid transparent;
    border-bottom: 10px solid #2a3142;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 138px;
    z-index: 10;
    top: -11px;
  }
  
  .headerIcon {
    width: 24px;
    height: 24px;
  }
  
  .bellIconSpecialPadding {
    padding: 10px 12px !important;
  }
  
  .img12 {
    width: 12px;
    height: 12px;
  }
  
  .filterModal {
    width: 540px;
    min-height: max-content;
    max-height: 90vh;
    background: #f4f6fb;
    border-radius: 24px;
    @media (max-width: 500px) {
      width: -webkit-fill-available;
      width: -moz-available;
      width: fill-available;
    }
  }
  
  .pd24 {
    padding: 24px;
  }
  
  .topHeaderBgWhite {
    background-color: white;
    border-radius: 24px 24px 0px 0px;
  }
  
  .img32 {
    height: 32px;
    width: 32px;
  }
  
  .boxShadowTop {
    background-color: white;
    box-shadow: 0px 2px 16px rgba(28, 28, 28, 0.04);
  }
  
  .paddingBottom0 {
    padding-bottom: 0;
  }
  
  .searchableInput {
    label {
      margin-left: 30px;
    }
    input {
      border-radius: 100px !important;
      padding-left: 40px !important;
      border: 1px solid #e6e9ef;
    }
    input:hover {
      border: 2px solid $red-500;
    }
    input:focus {
      border: 2px solid $red-500;
    }
  }
  
  .iconSearch {
    position: absolute;
    top: 16px;
    z-index: 2;
    left: 38px;
  }
  
  .img16 {
    height: 16px;
    width: 16px;
  }
  
  .px24 {
    padding: 0px 24px 0px 24px;
  }
  
  .padding24pxTopBottom {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  
  .borderDotted {
    border-bottom: 1.5px dashed #f5f5f5;
  }
  
  .dataBox {
    height: 67vh;
    overflow-y: auto;
  }
  
  .dataBoxNonSearch {
    min-height: fit-content;
    max-height: 67vh;
    overflow-y: auto;
  }
  
  .height14px {
    height: 14px;
  }
  
  .dataBox2 {
    background: $white;
    border-radius: 20px;
    margin-bottom: 24px;
  }
  
  .colorGreyText {
    color: #afb4c0;
  }
  
  .modalRadius {
    > div:nth-child(2) {
      border-radius: 24px;
    }
  }
  
  .padding16px {
    padding: 16px;
  }
  
  .borderBottomAccount {
    border-bottom: 1px solid #e6e9ef;
  }
  
  .padding24X {
    padding-left: 24px;
    padding-right: 24px;
  }
  
  .img200px {
    height: 200px;
    width: 200px;
  }
  
  .indigoColor {
    color: $indigo-500;
    opacity: 0.5;
  }
  
  .inactiveTag {
    background: #ffedef;
    border-radius: 6px;
    padding: 4px 8px;
    color: $red-700;
    width: fit-content;
  }
  
  .centralSpinner {
    top: 50%;
    left: 46%;
  }
  
  .crossImagePosition {
    right: 18px;
  }
  
  .sideBarPadding {
    padding: 20px;
  }
  
  .shiftTopHeader {
    top: 0px;
  }
  
  .topBarPading {
    padding-top: 100px;
  }
  
  .loginButton {
    span {
      border-radius: 12px;
      margin: 12px 0px;
      background-color: $red-500;
  
      @media only screen and (min-width: 992px) {
        margin: 12px 36px;
      }
    }
  }
  
  .loginBtnTxt {
    font-size: 14px;
  
    @media only screen and (min-width: 992px) {
      font-size: 16px;
    }
  }
  
  .restaurantLocationModals {
    > div:nth-child(2) {
      width: 480px;
      height: 650px;
      border-radius: 24px;
      @media screen and (max-width: 992px) {
        border-radius: 0%;
        width: 100vw;
        height: 100vh;
      }
    }
  }
  
  .restaurantLocationModalsLarge {
    > div:nth-child(2) {
      width: 480px;
      height: 650px;
      border-radius: 24px;
    }
  }
  
  .loginModals {
    > div:nth-child(2) {
      width: 480px;
      height: 650px;
      border-radius: 24px;
      @media (max-width: 1200px) {
        width: 440px;
        height: 550px;
      }
      @media (max-width: 500px) {
        width: 100%;
        height: 80vh;
      }
    }
  }
  
  .loginModalsLarge {
    > div:nth-child(2) {
      width: 480px;
      height: 650px;
      border-radius: 24px;
      @media (max-width: 1200px) {
        width: 440px;
        height: 550px;
      }
      @media (max-width: 500px) {
        width: 100%;
        height: 80vh;
      }
    }
  }
  
  .personalDetailsModal {
    > div:nth-child(2) {
      width: 600px;
      height: 600px;
      border-radius: 24px;
      @media screen and (max-width: 992px) {
        width: 100vw;
        height: 100vh;
        border-radius: 0%;
      }
    }
  }
  
  .restaurantLocationModal {
    > div:nth-child(2) {
      width: 600px;
      height: 750px;
      border-radius: 24px;
      @media screen and (max-width: 992px) {
        width: 100vw;
        height: 100vh;
        border-radius: 0;
      }
    }
  }
  
  .redColorText {
    color: $red-500;
  }
  
  .grey300Text {
    color: #d0d4dc;
  }
  
  .tealTextColor {
    color: $teal-800;
  }
  
  .width50 {
    width: 50%;
  }
  
  .finalStateModal {
    > div:nth-child(2) {
      width: 400px;
      height: 316px;
      border-radius: 24px;
      background: var(--basewhite-000, #fff);
      @media screen and (max-width: 992px) {
        width: 80vw;
        height: 220px;
      }
    }
  }
  
  .finalStateImage {
    width: 120px;
    height: 120px;
    margin-top: 40px;
    margin-bottom: 16px;
    padding: 24px;
    @media screen and (max-width: 992px) {
      width: 80px;
      height: 80px;
      margin-top: 24px;
    }
  }
  
  .finalStateText {
    width: 350px;
    height: 76px;
    text-align: center;
    font-size: 24px;
    line-height: 1.5em;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 992px) {
      height: 24px;
      font-size: 18px;
    }
  }
  
  .finalStateBox {
    height: 150% !important;
  }
  
  .finalStateButton {
    border-top: 2px solid #e6e9ef;
    height: 60px;
    @media screen and (max-width: 575px) {
      height: 55px !important;
      margin-top: 7%;
    }
  
    @media screen and (min-width: 575px) and (max-width: 992px) {
      height: 55px !important;
      margin-top: 2%;
    }
  }
  
  .widthInherit {
    width: inherit;
  }
  
  .onboardingModal {
    > div:nth-child(2) {
      border-radius: 24px;
      width: 440px;
      height: 448px;
      @media screen and (max-width: 992px) {
        height: 100%;
        width: 100%;
        border-radius: 0%;
      }
    }
  }
  
  .onboardingModalLarge {
    > div:nth-child(2) {
      border-radius: 24px;
      width: 440px;
      height: 475px;
      @media screen and (max-width: 992px) {
        height: 100%;
        width: 100%;
        border-radius: 0%;
      }
    }
  }
  
  .onboardingSetupModals {
    > div:nth-child(2) {
      width: 480px;
      height: 650px;
      border-radius: 24px;
      @media screen and (max-width: 992px) {
        height: 100vh;
        width: 100vw;
        border-radius: 0%;
      }
    }
  }
  
  .onboardingModalDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .onboardingImage {
    width: 160px;
    height: 160px;
    border-radius: 400px;
    background: var(--indigoindigo-50, #f4f6fb);
    margin: 40px 140px 24px 140px;
  }
  
  .onboardingModalImage {
    width: 100px;
    height: 100px;
    margin: 30px;
  }
  
  .onboardingHeader {
    width: 392px;
    margin-bottom: 4px;
  }
  
  .onboardingSubHeader {
    width: 392px;
    color: #767c8f;
    margin-bottom: 40px;
    @media screen and (max-width: 992px) {
      text-wrap: wrap;
      width: 80%;
    }
  }
  
  .onboardingButtonsForMobile {
    visibility: hidden;
    display: none;
    height: 0px;
    @media screen and (max-width: 992px) {
      height: 20%;
      visibility: visible;
      border: 0px;
      width: 100vw;
      overflow-x: hidden;
      display: flex;
      align-items: end;
      justify-content: end;
      padding: 24px;
    }
  }
  
  .onboardingButtons {
    width: 440px;
    height: 108px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--greygrey-200, #ebebeb);
    padding: 24px;
    @media screen and (max-width: 992px) {
      border: 0px;
      width: 100vw;
      visibility: hidden;
    }
  }
  
  .modalScreenDimension {
    @media screen and (max-width: 992px) {
      height: 100vh;
      overflow-y: hidden;
    }
  }
  
  .onboardingSetupModal {
    @media screen and (max-width: 992px) {
      height: 100% !important;
      width: 100vw;
    }
  }
  
  .onboardingButtonsHardCheck {
    width: 440px;
    height: 108px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--greygrey-200, #ebebeb);
    padding: 24px;
    @media screen and (max-width: 992px) {
      border: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100vw;
      padding: 10px;
    }
  }
  
  .bottomBarIcon {
    height: 24px;
    width: 24px;
  }
  
  .bottomBarIconText {
    font-size: 12px;
    margin: 6px 0px 0px 0px;
  }
  
  .headerWithSearchbarContainer {
    justify-content: center;
    flex-direction: column;
  }
  
  .headerSubheading {
    color: #ef4f5f;
  }
  
  .changeCatModal {
    height: 70vh;
    overflow-y: scroll;
  
    > div:nth-child(2) {
      width: -webkit-fill-available;
      width: -moz-available;
      width: fill-available;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  
  .changeCatModalHeader {
    height: 70vh;
    overflow-y: scroll;
  }
  
  .changeCatHeader {
    height: 50px;
    border-bottom: 1px solid #c9c9c9;
  }
  
  .imgTile {
    width: 20%;
  }
  
  .imgChangeCat {
    background-color: #f5f5f5;
    border-radius: 16px;
    overflow: hidden;
  
    img {
      width: 90%;
      height: 100%;
      flex-shrink: 0;
    }
  }
  
  .imgActiveCat {
    background-color: #ffedef !important;
  }
  
  .greyText {
    color: #696969;
  }
  
  .headerWithSearchbarHeading {
    font-size: 16px;
  }
  
  .headerDeliveryInfoImg {
    height: 22px;
    width: auto;
  }
  
  @media only screen and (max-width: 1450px) {
    .padding24Xaxis {
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .pdIconRight {
      margin-right: 10px;
    }
  
    .wdMinSaveWithMyList {
      min-width: auto;
    }
  
    .deliveryHeader {
      min-width: 300px;
    }
  
    .headerDeliveryInfo {
      font-size: 18px;
    }
  
    .headerDeliveryInfoImg {
      height: 20px;
      width: auto;
    }
  
    .headerAddressInfo {
      font-size: 13px;
    }
  }
  
  .customMarginLeft {
    margin-left: 1.5vh;
  }
  
  .chevronDownIcon {
    width: 15px;
    height: 15px;
  }
  
  .verticalLine {
    height: 50px;
    margin-left: 10px;
  }
  
  .whiteBorderLeft {
    border-left: 1px solid white;
  }
  
  .blackBorderLeft {
    border-left: 1px solid black;
  }
  
  .extendedHeader {
    background-color: white;
    padding: 1vh 0 1vh 0;
  }
  
  .widhtfill {
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
  }
  
  .extendedHeaderPadding {
    // padding: 5vh 0vh 0vh 0vh;
  }
  
  .horizontalLine {
    border-top: 1px solid $gray-100;
  }
  
  .locationFillImage {
    height: 33px;
    width: 30px;
    margin: 0vh 0.8vh 0vh 1vh;
  }
  
  .extendedHeaderHeight {
    height: 150px;
  }
  
  .emptySpace {
    width: 2px;
  }
  