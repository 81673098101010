@import "../../../styles/variables.scss";

.promoWrapper {
  display: inline-block;
}

.voucherCard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  border: 0.5px solid $sushi-gray-border;
  border-radius: 8px;
  margin-top: 20px;

  .voucherCardLeft {
    flex-basis: 130px;
    flex-shrink: 1;
    position: relative;
    font-size: 24px;
    background-color: red;
    padding: 5px 14px;
    color: $sushi-red;
    background-color: $red-smoke;
    display: flex;
    align-items: center;
    border-radius: 8px 0 0 8px;
    height: calc(100% - 1px);
    font-weight: 800;
    margin-top: 1px;
  }

  .voucherCardRight {
    padding: 5px 14px;
    border-radius: 0 0 8px 8px;
  }

  .punch {
    background-color: #fff;
    position: absolute;
    width: 14px;
    height: 7px;
    border: 0.5px solid $sushi-gray-border;
  }

  .punchTop {
    top: -1px;
    border-radius: 0 0 28px 28px;
    border-top: 0;
    right: -5px;
  }

  .punchBottom {
    bottom: -1px;
    border-radius: 28px 28px 0 0;
    border-bottom: 0;
    right: -5px;
  }
}

.promoImage {
  margin: auto;
  max-width: 150px !important;
  height: auto !important;
}

.animationCont {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.cont {
  width: 1222px !important;
  height: 100%;
  position: static !important;
  @media (max-width: 1200px) {
    width: 100% !important;
}
overflow: hidden;
}

.alternateCont {
    height: 100%;
    position: static !important;
}

.paddle {
  position: absolute;
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 9.6px 9.6px rgba(54, 54, 54, 0.08),
    0px 19.2px 19.2px rgba(54, 54, 54, 0.06);
  top: 40% !important;
}

.leftPaddle {
  left: 10px;
}
.rightPaddle {
  right: 10px;
}

.PromotionRailCard{
  padding-right: 24px !important;
}

.limitOverflow {
  width: 100%;
  overflow: hidden;
}