@import "../../../styles/variables.scss";
.quantityChangeHandler {
    display: flex;
    align-items: stretch;
    border: 1px solid $red-border;
    border-radius: 8px;
    overflow: hidden;
    width: 124px;
    font-size: 16px;
    .tab {
        background-color: $red-white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $pri-green;
        font-size: 24px;
        padding: 8px 8px;
        svg {
            fill: $pri-green;
        }
    }
    input {
        width: 50px !important;
        border-radius: 0 !important;
        font-weight: 500 !important;
        border: 0;
        text-align: center;
        font-size: 14px;
    }
    &.large {
        width: 160px;
        height: 48px;
        border-radius: 12px !important;
        .tab {
            width: 60px;
            svg {
                width: 17px;
                height: 17px;
            }
        }
        input {
            font-size: 16px;
            width: 60px !important;
            padding: 0 4px !important;
        }
    }
    &.small {
        width: 98px;
        .tab {
            font-size: 18px;
            padding: 8px 5px;
            svg {
                width: 18px;
                height: 18px;
            }
        }
        input {
            font-size: 14px;
            width: 40px !important;
            padding: 0 4px !important;
        }
    }
    &.smallest {
        width: 90px;
        height: 32px;
        .tab {
            font-size: 18px;
            padding: 8px 4px;
            svg {
                width: 18px;
                height: 18px;
            }
        }
        input {
            font-size: 14px;
            width: 36px !important;
            padding: 0 4px !important;
        }
    }
    &.rail {
        width: 197px;
        height: 32px;
        .tab {
            font-size: 15px;
            padding: 10px 25px;
            svg {
                width: 15px;
                height: 15px;
            }
        }
        input {
            font-size: 15px;
            width: 65px !important;
            padding: 0 4px !important;
        }
    }
}

.disableCounter {
    border: 1px solid $sushi-gray-border !important;
}

.loaderMsg {
    position: absolute;
    bottom: -12px;
    text-align: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    color: white;
    font-size: 15px;
    background-color: $red-600;
    border: 0 !important;
    max-width: 50px;
    text-align: center;
    font-family: "OurLexend-SemiBold", Helvetica, sans-serif;
}

.disabled {
    background-color: $gray-300 !important;
}

.counterInput {
    font-family: "OurLexend-Regular", Helvetica, sans-serif !important;
}