.container {
  display: flex;
  justify-content: center;
  padding: 40px;
}

.page {
  min-height: 400px;
  flex-grow: 1;

  @media screen and (max-width: 992px) {
   min-height: initial;
}
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
