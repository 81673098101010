@import "../../../../styles/variables.scss";
.bannerCont {
    border-radius: 24px;
    background-color: $white;
    box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
    padding-top: 16px;
    display: flex;
    border: 1px solid $gray-300;
    height: 140px;
    width: 100%;
}

.bannerContSm {
    border-radius: 24px;
    box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
    background-color: $gray-card-background;
    padding-top: 12px;
    display: flex;
    border: 1px solid $gray-300;
    height: 120px;
}

.image {
    transform: translateY(14px);
    background-size: contain;
    width: 80px;
    height: 86px;
    margin-left: 16px;
    background-repeat: no-repeat;
    background-position-y: center;
    margin-left: 16px;
}

.imageSm {
    transform: translateY(8px);
    background-size: contain;
    width: 80px;
    height: 86px;
    background-repeat: no-repeat;
    background-position-y: center;
    margin-left: 16px;
}

.content {
    display: flex;
    margin-left: 16px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.submitBtn {
    width: 212px !important;
    margin-right: 30px;
    >span {
        padding: 9px 12px !important;
        height: 40px;
        align-items: center;
        border-radius: 4px;
        position: relative;
        >span {
            font-size: 18px;
            display: inline-flex;
            align-items: center;
            >span:nth-child(2) {
                font-size: 18px;
                padding: 0 !important;
                position: absolute;
                top: 0;
                right: 6px;
            }
        }
    }
}

.submitBtnSm {
    width: 153px !important;
    margin-right: 20px;
    >span {
        padding: 7px 12px !important;
        height: 32px !important;
        align-items: center;
        border-radius: 4px;
        position: relative;
        >span {
            font-size: 13px;
            display: inline-flex;
            align-items: center;
            >span:nth-child(2) {
                font-size: 13px;
                padding: 0 !important;
                position: absolute;
                top: 0;
                right: 6px;
            }
        }
    }
}