/*Mixins for global styles*/

/*Box Shadow-------*/
@mixin box-shadow($x: 0px, $y:0px, $blur:4px, $rad:0px, $color:$shadow-lightest-gray) {

    box-shadow: $x $y $blur $rad $color;
}

/*Gradient -------*/
@mixin linear-gradient-bg($dir, $from, $to) {
    background-image: -o-linear-gradient($dir, $from, $to);
    background-image: -moz-linear-gradient($dir, $from, $to);
    background-image: -webkit-linear-gradient($dir, $from, $to);
    background-image: linear-gradient($dir, $from, $to);
}

@mixin linear-multi-gradient-bg($first, $second, $third, $fourth) {
    background: linear-gradient($first, $second, $third, $fourth);
    background: -o-linear-gradient($first, $second, $third, $fourth);
    background: -moz-linear-gradient($first, $second, $third, $fourth);
    background: -webkit-linear-gradient($first, $second, $third, $fourth);
    background: linear-gradient($first, $second, $third, $fourth);
}