@import "../../../styles/variables.scss";

.wd600px{
    width: 600px;
    @media screen and (max-width: 992px ){
      width: 100vw;
    }
}

.mapPointer{
    height: 40px;
    width: 30px;
}

.mapPopup{
  background: #2C3875;
  border-radius: 100px;
  padding: 11px 16px;
  color: white;
  width: fit-content;
  margin-bottom: 15px;
}

.mapPopupUnservicable{
    background: #BF2938;
    border-radius: 100px;
    padding: 11px 16px;
    color: white;
    width: fit-content;
    margin-bottom: 15px;
}

.centerAlignPointer {
    top: 90px;
    left: 180px;
    
    @media screen and (max-width: 575px) {
      height: 61vh;
      width: 100%;
      top: -51px;
      left: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }

    @media screen and (min-width: 575px) and (max-width: 992px) {
     left: 0px;
     height: 61vh;
     width: 100%;
     top: 0px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     pointer-events: none;
    }
}

.locationHeading{
  font-size: 24px;
  @media screen and (max-width: 992px) {
    width: 100vw;
    font-size: 18px;
  }
}

.downTriangleLocation {
    height: 0px;
    width: 0px;
    border-top: 10px solid #2C3875;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    z-index: 10;
    top: 53px;
    left: 110px;
  }

  .downTriangleLocationUnservicable{
    height: 0px;
    width: 0px;
    border-top: 10px solid #BF2938;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    z-index: 10;
    top: 53px;
    left: 110px;
  }

  .modalHeader{
    @media screen and (max-width:992px) {
      height: 156px;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      border: 1px solid #E6E9EF;
      position: relative;
      z-index: 5;
      background-color: #fff;
    }
  }

  .mapPage{
    @media screen and (max-width:992px) {
      width: 100%;
      top: -1%;
      left: 0%;
      position: absolute;
      margin-top: -30px !important;
    }
  }

  .modalHeaderSearchCover{
    visibility: hidden;
    @media screen and (max-width:992px) {
      visibility: visible;
      width: 100%;
      opacity: 1000;
    }
  }

  .gpsFocus{
    height: 48px;
    width: 48px;
    right: 0;
    bottom: 0;
    @media screen and (max-width:992px) {
      visibility: hidden;
    }
  }

  .gpsFocusMobile{
    height: 60px;
    width: 100%;
    bottom: 10%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    visibility: visible;
    margin-bottom: 4%;
    @media screen and (min-width:993px) {
      visibility: hidden;
    }
  }

  .gpsFocusMobileImage{
    height: inherit;
    width: 200px;
  }

  .mapDimensionWithTag{
    height: 350px;
    @media screen and (max-width:992px) {
      height: calc(100vh - 325px);
    }
  }

  .mapDimensionWithoutTag{
    height: 400px;
    @media screen and (max-width:992px) {
      height: calc(100vh - 260px);
    }
  }


  .bottomButtonFixed {
    border-top: 1px solid #F2F4F7;
    box-shadow: 0px -2px 16px rgba(28, 28, 28, 0.04);
    width: 100%;
    position: absolute;
    bottom: 0%;
    @media screen and (max-width:992px) {
      border: none;
      box-shadow:none
    }
  }

  .modalFooter{
    @media screen and (max-width:992px) {
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      position: absolute;
      bottom: 50px;
      z-index:index 6;
    }

    @media screen and (max-width:450px) {
      position: absolute;
      bottom: 70px;
      z-index:index 6;
      height: 90px;
      background-color:#fff;
      border-radius: none;
    }
  }

  .opacity8 {
    opacity: 0.8;
  }


.searchableInput{
    label {
        margin-left: 30px;
    }
    input{
        border-radius: 100px !important;
        padding-left: 40px !important;
        border: 1px solid #EBEBEB;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    }
  }
  
  
  .iconSearch {
    position: absolute;
    top: 16px;
    z-index: 2;
    left: 38px;
  }

  .searchCover {
    z-index: 10;
    width: 100%;
    top: 24px;
    @media screen and (max-width:992px) {
      visibility: hidden;
    }
  }

  .searchBox{
    height: 600px;
    border-top: 1px solid #F2F4F7;
    overflow-y: scroll;
    padding-top: 90px;
    position: relative;
    @media screen and (max-width: 992px) {
      height: 100%;
    }
  }

  .gpsBox{
    border: 1px solid #EBEBEB;
    border-radius: 12px;
    height: fit-content;
    padding: 10px;
  }

  .addressDatapart{
    border-bottom: 1.5px dashed #F5F5F5;
  }

  .h688px {
    min-height: 688px;
    @media screen and (max-width: 992px ){
      min-height: 100vh;
    }
  }

  .borderTopGrey {
    border-top: 1px solid #F2F4F7;
  }

  .textAreaLocation{
    textarea{
        height: 80px;
    }
    textarea:focus{
        border: 1.5px solid #1C1C1C;
    }
  }

  .textAreaPincode {
    textarea {
      height: 50px;
    }

    textarea:focus {
      border: 1.5px solid #1C1C1C;
    }
  }

  .floorTab {
    border: 1.5px solid #E6E9EF;
/* primitives / Shadow 100 */

box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
border-radius: 12px;
padding: 12px 16px;
margin-right: 10px;
cursor: pointer;
  }

.floorTabSelected {
    background: #FFF5F6;
/* RED / RED500 */

border: 1.5px solid #EF4F5F;
box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
border-radius: 12px;
padding: 12px 16px;
margin-right: 10px;
cursor: pointer;
}


.whiteRectangleBlur {
  position: absolute;
width: 40px;
height: 64px;
left: 56px;
top: -11px;

background: #FFFFFF;
opacity: 0.24;
filter: blur(12px);
}

.spinnerPosition {
  position: absolute;
    left: 50%;
    top: 50%;
}

.tagline{
  height: 44px !important;
  padding: 12px 10px !important;
  text-align: center;
  width: 100%;
  @media screen and (max-width:992px) {
    visibility: hidden;
  }
}

.taglineMobile{
  visibility: hidden;
  @media screen and (max-width:992px) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    visibility: visible;
    height: 50px !important;
    padding: 12px 10px !important;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 150px;
    padding-top: 15px !important;
  }
  @media screen and (max-width:450px) {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    visibility: visible;
    height: 80px !important;
    padding: 12px 10px !important;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 130px;
    padding-top: 15px !important;
  }
}

.confirmLocationButton{
  span {
    border-radius: 12px;
  }
}