@import "../../../../styles/variables.scss";

.container {
  position: relative;

  .suggestionsContainer,
  .firstFocus {
    position: absolute;
    background-color: #fff;
    left: 0;
    max-height: 528px;
    overflow: auto;
    width: 100%;
    margin-top: 0;
    .suggestionsList {
      padding: 0;
      margin-top: 0;
      .suggestion {
        list-style: none;
        padding: 6px 16px;
        transition: background-color 0.1s ease-in-out;
        font-size: 1.5rem;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }

        &.suggestionHighlighted {
          background-color: #f5f5f5;
        }

        @media screen and (max-width: $md-screen) {
          padding: 0px 16px;
        }
      }
    }

    @media screen and (max-width: $md-screen) {
      max-height: calc(100% - 80px);
      overflow: auto;
      position: fixed;
      z-index: 11;
      top: 80px;
      background-color: #f4f6fb;
      border-radius: 0;
    }
  }
}

.searchBarSuggestions{
  overflow: scroll;
}

.loadingSearchResults{
  position: fixed;
  top: 80px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f4f6fb;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.searchBarSuggestions::-webkit-scrollbar {
  @media screen and (max-width: $md-screen) {
    display: none;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.searchBarSuggestions {
  @media screen and (max-width: $md-screen) {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.searchIcon {

  position: absolute;
  right: 0;
  top: 0;
  background-color: $white;
  height: 100%;
  padding: 0 25px;
  border-radius: 0px 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
}

.inputLoader {
  position: absolute;
  top: 0;
  right: 17px;
  margin-top: 20px;

  @media screen and (min-width: $md-screen) {
    right: 83px;
  }

  div {
    border-top-color: $red-border;
    color: $red-border;
  }
}

.searchIconLeft {
  right: auto;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 8px 0 0 8px;
  border-right: 0;
  padding: 0 8px 0 16px;

  @media screen and (max-width: $md-screen) {
    padding: 0 8px 0 12px;
  }
}

.searchInput {
  outline: none;
  height: 100%;
  border-radius: 24px !important;
  @media only screen and (max-width: $md-screen) {
    padding-left: 44px !important;
    box-shadow: 0px 2px 8px rgba(28, 28, 28, 0.08);
  }
}

.suggHead {
  border-top: 1px solid #F2F4F7;
  padding: 12px 0px;
  color: $grey-600;
}

.groupMargin{
  margin-top: 16px;
}

.suggHeadMobile{
  border-bottom: 1px solid #F2F4F7;
  border-top: none;
}

.suggTopEle {
  padding-top: 10px;

  @media only screen and (max-width: $md-screen) {
    padding-top: 0px;
  }
}

.innerFocusBox{
  border-top: 1px solid #F2F4F7;
}

.firstFocus {
  padding: 0px 16px;
  height: 100vh;
  @media only screen and (min-width: $md-screen) {
    height: initial;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    background: #fff;
    padding: 0px 10px 10px 10px;
    border-left: 1px solid $grey-200;
    border-bottom:  1px solid $grey-200;
    border-right: 1px solid $grey-200;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 24px 24px;
    z-index: 10;
  }
}

.clear {
  color: $notification-red;
  font-size: 14px;
  cursor: pointer;
}

.tabs {
  display: inline-flex;
  align-items: center;
  border: 1px solid #E6E9EF;
  border-radius: 20px;  
  padding: 4px 16px 4px 4px;
  margin: 10px 10px 2px 0;
  color: #293142;
  font-size: 14px;
  cursor: pointer;
  vertical-align: middle;

  &:first-child {
    margin-top: 10px;
  }

  img {
    width: 32px;
    height: 32px;
    border: 1px solid #E6E9EF;
    border-radius: 20px;  
  }

  @media screen and (max-width: $md-screen) {
    background-color: white;
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  //margin-bottom: 10px;
  vertical-align: middle;
  cursor: pointer;

  // border-bottom: 1px solid $gray-200;
  margin-right: 10px !important;
  img {
    width: 100px;
    max-height: 100px;
  }
}

.imageList{
  border: 1px solid #E6E9EF;
  border-radius: 16px;

  @media screen and (max-width: $md-screen) {
    background-color: white;
  }
}

.oosIcon {
  height: 15px;
  transform: translateY(1.5px);
}

.opacity {
  opacity: 0.6;
}

.greyColor {
  color: #1C1C1C !important
}

.imgSuggest{
  width: 34px;
}
.searchInputBox{
  height: 50px;
  >div:nth-child(2){
    border-radius: 0px 0px 24px 24px !important;
  }
  input {
    font-size: 16px !important;
  }
}

.searchInputSelected{
  input{
    @media screen and (min-width: $md-screen) {
      border-radius: 24px 24px 0px 0px !important;
      box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);
      border-bottom: 0 !important;
      background-color: white !important;
    }
  }
}

.img16 {
  height: 16px;
  width: 16px;
}

.imgSuggest{
  width: 34px;
}

.colorGrey700 {
  color: #596378 !important;
}

.margin0{
  margin: 0;
}

.suggestionTopImage {
  border: 1px solid #E6E9EF;
  border-radius: 100px;
  height: 48px;
  width: 48px;
  img {
    width: 100%;
  }
}

.suggestionTopImageSearch {
  img {
    width: 18px;
    height: 18px;
  }
  margin-bottom: 6px;
}

.suggestionProduct{
  height: 48px;
  width: 48px;
  img{
    width: 100%;
  }
}

.listHeader {
  width: 100px;
  text-align: center;
  overflow-x: none;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.listHeader::-webkit-scrollbar { 
display: none;  /* Safari and Chrome */
}

.autosuggestScroll{
    top: 2px;
    height: 48px;
    left: 44px;
    width: 300px;
    pointer-events: none;
  li{
    text-align: left !important;
    height: 48px;
  }
}

.greyTextSearch {
  color: #AFB4C0
}

.redtextSearch {
  color: #ed5968f3;
}

.suggestionContainer{
  padding: 16px;
  box-shadow: 0px 1px 4px 0px rgba(28, 28, 28, 0.06);
  border-top: 1px solid #FFF;
}
