@import "./variables.scss";
@import "./mixins.scss";

button {
  border: 0;
  outline: none;

  &:focus {
    outline: none;
    border: 0;
    width: auto;
  }
}

.btn-icon {
  position: absolute;
  right: 7px;
  top: 7px;
}

.btn {
  display: inline-block;
  padding: 14px 28px;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s ease-in-out;

  &.small {
    padding: 10px 28px;
  }

  &.round {
    border-radius: 50%;
    padding: 0;
    width: 25px;
    height: 25px;
  }

  &:focus {
    outline: none;
    border: 0;
  }
}

.btn-info {
  background-color: $info-bg;
  color: $info-color;
}

.btn-white {
  @include linear-gradient-bg(50deg, #fff, #fff);
  color: $pri-green;
  border: 1px solid $pri-green;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-green {
  @include linear-gradient-bg(50deg, $pri-green, $pri-green);
  color: #fff;
}

.btn-red {
  @include linear-gradient-bg(50deg, $red-border, $red-border);
  color: #fff;
}

.btn-green-flat {
  padding: 10px 25px;
}

.btn-disabled {
  @include linear-gradient-bg(50deg, $sushi-gray, $sushi-gray);
  color: #fff;
  pointer-events: none;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-orange {
  @include linear-gradient-bg(50deg, $sec-orange, $pri-orange);
  color: #fff;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-blue {
  @include linear-gradient-bg(50deg, $sec-blue, $pri-blue);
  color: #fff;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-black {
  @include linear-gradient-bg(50deg, #000, #000);
  color: #fff;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn--dark-shadow {
  &:hover {
    box-shadow: 0 4px 8px $gray-dark;
  }
}

.btn--no-shadow {
  &:hover {
    box-shadow: 0 0 0 $gray-dark;
  }
}

.btn-sm {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
}

.btn-expand {
  width: 100%;
  text-align: center;
}

.btn-wrapper {
  margin-top: 20px;
  position: relative;
}

.btn-yellow {
  @include linear-gradient-bg(50deg, $pri-yellow, $pri-yellow);
  color: $sec-yellow;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-red {
  @include linear-gradient-bg(50deg, $pri-red, $pri-red);
  color: #fff;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-red-smoke {
  @include linear-gradient-bg(50deg, $red-smoke, $red-smoke);
  color: $red;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-active-filter {
  background-color: $blue-bg !important;
  border: 1px solid $blue-bg !important;
  color: $blue-color !important;
  padding-right: 30px !important;
}

.nohover {
  &:hover {
    box-shadow: none;
    cursor: default;
  }
}

.btn-transparent {
  border: 1px solid $sushi-gray-dark;
  color: $sushi-gray-dark;
}

.btn-transparent-red {
  background-color: rgba(0, 0, 0, 0);
  color: $pri-red;
  border: 1px solid $pri-red;
  box-sizing: border-box;
  padding: 13px 27px;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-transparent-green {
  background-color: rgba(0, 0, 0, 0);
  color: $pri-green;
  border: 1px solid $pri-green;
  box-sizing: border-box;
  padding: 8px 27px;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-transparent-gray {
  background-color: #fff;
  color: $sushi-gray-med;
  border: 1px solid $sushi-gray-med;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 4px 8px $gray-med;
  }
}

.btn-transparent-white {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 10px 15px;
}

.btn-normal {
  padding: 14px 28px !important;
}

.btn-flat {
  padding: 0 0;
}

.btn-green-small {
  padding: 4px 16px;
  border-radius: 8px;
}

.btn-card-small {
  padding: 4px 16px;
  border-radius: 8px;
}

.thin-sushi-btn {
  width: 90px !important;
  > span {
    padding: 5px 6px !important;
    height: 32px;
    align-items: center;
    background: $red-white;
    border-radius: 8px;
    position: relative;

    > span {
      font-size: 15px;
      display: inline-flex;
      align-items: center;

      > span:nth-child(2) {
        font-size: 15px;
        padding: 0 !important;
        position: absolute;
        top: -3px;
        right: 6px;
      }
    }
  }
}

.thin-sushi-btn:hover {
  background: $red-white;
}

.thin-sushi-btn-mweb {
  > span {
    width: 94px !important;
    padding: 5px 6px !important;
    height: 34px;
    align-items: center;
    background: $red-white;

    > span {
      display: inline-flex;
      align-items: center;
    }
  }
}

.fat-sushi-btn {
  > span {
    height: 36px;
    width: 98px !important;
    padding: 4px 10px !important;
    background: $red-white;

    > span {
      display: inline-flex;
      align-items: center;
    }
  }
}

.big-sushi-btn {
  width: 160px !important;
  > span {
    padding: 5px 6px !important;
    height: 48px;
    align-items: center;
    background: $red-white;
    border-radius: 12px;
    position: relative;
  }
}

.btn-otp-res {
  display: inlin-flex;
  flex-basis: 114px;
  text-align: center;
  flex-grow: 1;
  padding: 14.5px 7px;
  border-radius: 6px;
}

.btn-mid {
  min-width: 22rem !important;

  span {
    min-width: 22rem !important;
  }
}

.landingButton{
  display:flex;
  justify-content: center;
  width: 700px;
}
