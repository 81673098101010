@font-face {
    font-family: "Okra-Regular";
    src: url("../../public/assets/fonts/Okra-Regular.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Okra-Medium";
    src: url("../../public/assets/fonts/Okra-Medium.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Okra-Light";
    src: url("../../public/assets/fonts/Okra-Light.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Okra-SemiBold";
    src: url("../../public/assets/fonts/Okra-SemiBold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Okra-Bold";
    src: url("../../public/assets/fonts/Okra-Bold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "Okra-ExtraBold";
    src: url("../../public/assets/fonts/Okra-ExtraBold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "HK-Grotesk";
    src: url("../../public/assets/fonts/hk-grotesk.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "HK-Grotesk-Bold";
    src: url("../../public/assets/fonts/hk-grotesk-bold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "HK-Grotesk-SemiBold";
    src: url("../../public/assets/fonts/hk-grotesk-semibold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-Regular";
    src: url("../../public/assets/fonts/OurLexend-Regular.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-Medium";
    src: url("../../public/assets/fonts/OurLexend-Medium.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-SemiBold";
    src: url("../../public/assets/fonts/OurLexend-SemiBold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-Bold";
    src: url("../../public/assets/fonts/OurLexend-Bold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-Light";
    src: url("../../public/assets/fonts/OurLexend-Light.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-Regular";
    src: url("../../public/assets/fonts/OurLexend-Regular.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-Medium";
    src: url("../../public/assets/fonts/OurLexend-Medium.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-SemiBold";
    src: url("../../public/assets/fonts/OurLexend-SemiBold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: "OurLexend-Bold";
    src: url("../../public/assets/fonts/OurLexend-Bold.ttf") format("truetype");
    font-display: swap;
    font-style: normal;
}