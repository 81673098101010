@import "../../../../styles/variables.scss";

.modal {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 30px;
  margin-top: -50px;


  .close {
    position: absolute;
    top: 75px;
    right: 16px;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .header {
    margin: auto;
    width: 60%;
    padding: 16px;
    padding-top: 24px;

    img {
      height: 120px;
      width: 100px;
      margin-right: 10px;
    }

    @media screen and (max-width: $md-screen) {
        img {
            height: 70px;
            width: 50px;
        }
        width: 100%;
      }

      .headerTexts {
          margin-top: 27px;
          @media screen and (max-width: $md-screen) {
            width: 50%;
          }
      }

      .coinAnimation {
          margin: 0 -60px;
          canvas {
              width: 350px;
          }
      }
  }

  .learnMore {
    position: absolute;
    padding: 24px 10px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .learnMoreContainer {
    padding-bottom: 70px;

    span {
      font-size: 18px;
    }
  }
}

.animationContainer {
  position: absolute;
  top: -100%;
  left: -100%;
  bottom: -100%;
  right: -100%;

  * {
    height: 100%;
  }
}
