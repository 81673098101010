@import "../../../styles/variables.scss";

.footer {
  padding-bottom: 24px;
  padding-top: 40px;
  background-color: #f4f6fb;
  color: #293142;
  line-height: 20px;

  @media only screen and (max-width: $md-screen) {
    padding-bottom: 70px;
    padding-left: 16px;
    padding-right:16px;
    padding-top:24px;
  }

  .facebookIcon {
    svg {
      fill: $facebook-icon-color;
    }
  }

  .twitterIcon {
    svg {
      fill: $twitter-icon-color;
    }
  }

  .content {
    text-align: left;

    @media only screen and (max-width: $md-screen) {
      text-align: left;
      margin-bottom: 0px;
    }
  }

  .copyright {
    text-align: left;
    opacity: 0.40;

    @media only screen and (max-width: $md-screen) {
      text-align: left;
    }
  }

  .license {
    text-align: right;
    opacity: 0.40;
    color:$indigo-500;
    line-height: 20px;
    word-wrap: break-word;

    @media only screen and (max-width: $md-screen) {
      text-align: left;
    }
  }
}

.fssaiIcon {
  width: 36px;
  height: 18px;
  margin-right: 6px;
}

.phoneIcon {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.mailIcon {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.companyDetails {
  height: 196px;
  width: 320px;
  margin-right:64px;
  @media only screen and (max-width: $md-screen) {
    text-align: left;
    margin-bottom: 40px;
    margin-top:14px;
  }
}

.knowMore {
  height: 164px;
  width: 200px;
  margin-right:64px;
  @media only screen and  (max-width: $md-screen) {
    text-align: left;
    margin-bottom: 40px;
  }
}

.followUs {
  height: 84px;
  width: 200px;
  @media only screen and (max-width: $md-screen) {
    text-align: left;
    margin-bottom: 40px;
  }
}

.hpTextIcon {
  height: 49px;
  width: 175px;
  margin-bottom: 47px;
  @media only screen and (max-width: $md-screen) {
    text-align: center;
    height: 35px;
    width: 125px;
    margin-bottom: 14px;
  }
}

.hpIcon {
  height: 64px;
  width: 64px;
  margin-bottom: 16px;;
  @media only screen and (max-width: $md-screen) {
    text-align: center;
    height: 48px;
    width: 48px;
    margin-bottom: 8px;
  }
}

.appStoreIcon {
  height: 40px;
  width: 120px;
}

.playStoreIcon {
  height: 40px;
  width: 135px;
}

.store {
  @media only screen and (max-width: $md-screen) {
    text-align: left;
    margin-bottom: 14px;
  }
}

.textIcon {
  @media only screen and (max-width: $md-screen) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.centerPos {
  text-align: right;
  @media only screen and (max-width: $md-screen) {
    text-align: center;
  }
}