@import "../../../styles/variables.scss";

.myListContainer {
    width: 516px;
    border-radius: 24px 24px 24px 24px;
}

.myListBackgroundImage {
    height: 300px;
    width: inherit;
    border-radius: 24px 24px 0px 0px;
}

.greytext {
    color: #767C8F;
}

.myListCta {
    height: 56px;
    span {
        border-radius: 12px;
        font-size: 18px;
        font-weight: 400;
    }
}

.catalogheaderIcon {
    width: 24px;
    height: 24px;
}

.catalogcartItem {
    border: 1px solid #E6E9EF;
    border-radius: 100px;
    padding: 8px;
}

.pdpheaderIcon {
    width: 32px;
    height: 32px;
}

.pdpcartItem {
    border: 1px solid #E6E9EF;
    border-radius: 100px;
    padding: 12px;
}


