@import "../../../../styles/variables.scss";
@import "../../../../styles/zIndex.scss";

.card {
  width:220px;
  position: relative;
  padding:0px !important;
  margin: 0px 30px 15px 0px;
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
  border-radius: 24px;
  border: 1px solid $gray-200;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &.small {
    height: 290px !important;
  }
  z-index:1;
}

.circleBtn {
  position: absolute;
  top: -15px;
  right: 0px;
  z-index: 8;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $red-600;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 15px;
  cursor: pointer;
}

.image {
  margin: 40px auto 16px;
  max-width: 80px;
  max-height: 80px;
  width: 80px;
  height: 80px;
  border-radius: 4px;
}

.productName {
  width: 146px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2;
  -webkit-box-orient: vertical;
}

.animation {
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

.imageNIS {
  margin: 15px 12px 8px 0px;
  max-width: 146px;
  max-height: 146px;
  width: 146px;
  height: 146px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.notifyMe {
  width: 184px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  cursor: pointer;
  position: absolute;
  top: -30px;
  z-index: 2;
  background-color: white;
}

.offerImg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.deliveryslot {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.deliveryImg {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.addBtnDisabled {
  width: 90%;
  position: relative;
  height: 32px !important;
  font-size: 15px;
  margin-left:11px;
  margin-right:11px;
  > span {
    background-color: $gray-card-background !important;
  }
}

.icon {
  position: absolute;
  font-size: 18px;
  right: 10px;
  top: 0;
}

.railBtn {
  width: 90% !important;
  height: 40px !important;
  margin-left:11px;
  margin-right:11px;
  > span {
    padding: 5px 6px !important;
    height: 32px;
    align-items: center;
    background: $red-white;
    border-radius: 8px;
    position: relative;

    > span {
      font-size: 18px;
      display: inline-flex;
      align-items: center;

      > span:nth-child(2) {
        font-size: 15px;
        padding: 0 !important;
        position: absolute;
        top: 0;
        right: 6px;
      }
    }
  }
}

.adderContainer {
  margin: 0px 11px 8px ; 
}

.offerTag {
  z-index: $z-offer-tag;
  padding: 1px 8px;
  position: absolute;
  top: 30px;
  right:0px;
  border-radius: 4px 0px 0 4px ;
}

.offerTagHeader {
  z-index: $z-offer-tag;
  padding: 1px 8px;
  position: absolute;
  top: 40px;
  right:0px;
  border-radius: 4px 0px 0 4px ;
}


.errorNudge {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
  position: absolute;
  top: 5px;
  width: 100%;
}

.space {
  min-height : 2vh;
  margin-top: 1px;
}

.headerCap {
  width:220px;
  height:35px;
  padding: 8px 10px 16px;
  position:relative;
  top: 35px;
  border: 1px solid $gray-200;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  margin-top:-35px;
  z-index:2;
}
