@import "../../../styles/variables.scss";
@import "../../../styles/zIndex.scss";

.filterPill {
    background: $white;

    border: 1px solid $gray-200;

    box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
    border-radius: 40px;
    padding: 8px 16px 8px 20px;
    width: fit-content;
}

.filterRow{
    position: sticky;
    top: 100px;
    z-index: $z-index-filters;
}

.fixedGreyBg{
    position: absolute;
      height: 28px;
      width: 100%;
      background-color: #f4f6fa;
      top: -19px;
      z-index: -1;
  }

.marginBottom24px{
    margin-bottom: 24px;
}

.paddingBottom14px{
    padding-bottom: 14px;
}

.img20{
    width: 20px;
    height: 20px;
}

.activePill{
    background: $red-100;
    
    border: 1px solid $red-500;
    
    box-shadow: 0px 1px 4px rgba(28, 28, 28, 0.06);
}

.filterModal {
    width: 540px;
    min-height: max-content;
    max-height: 712px;
    background: #F4F6FB;
    border-radius: 24px;
}

.modalRadius {
    >div:nth-child(2){
        border-radius: 24px;
    }
}

.padding24pxTopBottom {
    padding-top: 18px;
    padding-bottom: 18px;
}

.searchableInput{
    label {
        margin-left: 30px;
    }
    input{
        border-radius: 100px !important;
        padding-left: 40px !important;
        border: 1px solid #E6E9EF;
    }
    input:hover{
        border: 2px solid $red-500;
    }
    input:focus{
        border: 2px solid $red-500;
    }
}

.greyColor {
    color: #AFB4C0
}

.checkBoxDisabled{
    input {
        border: 2px solid #D0D4DC;
        background: $white;
    }
    input:checked{
        border: 2px solid #D0D4DC;
    }
    input:hover{
        background: $white;
        border: 2px solid #D0D4DC !important;
    }
    input:disabled{
        background: $white;
        border: 2px solid #D0D4DC;
    }
}

.dataBox {
    background: $white;
border-radius: 20px;
height: 540px;
overflow-y: auto;
margin: 0px 24px 24px 24px;
}

.dataBoxNonSearch{
    background: $white;
    border-radius: 20px;
    min-height: fit-content;
    max-height: 704px;
    overflow-y: auto;
    margin: 0px 24px 24px 24px;
}

.borderRadius0 {
    border-radius: 0px;
}

.boxShadowTop {
    background-color: white;
    box-shadow: 0px 2px 16px rgba(28, 28, 28, 0.04);
}

.pd24 {
    padding: 24px;
}

.px24{
    padding: 0px 24px 0px 24px;
}

.paddingBottom0{
    padding-bottom: 0;
}

.borderDotted{
    border-bottom: 1.5px dashed #F5F5F5;
}

.wd50{
    width: 50%;
}

.bottomCTAs{
    background-color: $white;
    position: absolute;
    bottom: 0px;
    height: 96px;
    width: 100%;
    border-radius: 0px 0px 24px 24px;
    border-top: 1px solid #EBEBEB;
    box-shadow: 0px -2px 16px rgba(28, 28, 28, 0.04);
}

.redClearCTA{
    color: $red-500;
}

.btnApply{
    height: 48px;
    width: 226px;
    span{
        font-size: 18px;
        border-radius: 8px;
    }
}

.checkBoxFilter{
    input {
        border: 2px solid $red-500;
    }
    input:checked{
        background: $red-500;
        border: 2px solid $red-500;
    }
    input:hover{
        border: 2px solid $red-500;
    }
}

.img200px {
    height: 200px;
    width: 200px;
}

.noContentDataBox{
    background-color: #F4F6FB;
}

.indigoColor {
    color: $indigo-500;
    opacity: 0.5,
}

.margin16Negative{
    margin-top: -16px;
}

.margin16 {
    margin-top: 16px;
}

.filterDiv{
    background-color: #f4f6fb;
    width: 100%;
   box-shadow: 5px 4px 0px 6px #f4f6fb;
}

.boxShadowOnScroll {
    box-shadow: 5px 4px 22px 6px #f4f6fb !important;
}

.iconSearch {
    position: absolute;
    top: 16px;
    z-index: 2;
    left: 38px;
}

.stickyRowShift{
    top: 192px !important;
}

.img32{
    height: 32px;
    width: 32px;
}

.img16{
    height: 16px;
    width: 16px;
}

.marginBottom65px {
    margin-bottom: 65px;
}

.bgWhite{
    background-color: white;
}

.topHeaderBgWhite {
    background-color: white;
    border-radius: 24px 24px 0px 0px;
}