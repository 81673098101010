@import "./../../../styles/variables.scss";
.chatBoxWrapper {
  background-color: $white;
  width: 40.2rem;
  min-height: 61rem;
 
  border-radius: 2rem;
  border: 1px solid $grey-200;
  background: $white;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
}

.chatPosition {
  position: fixed; 
  bottom: 43px;
  right: 100px;
  z-index: 10;
}

.chatBoxHeader {
  padding: 1rem 1rem;
}
.chatBoxHeaderRight {
}

.chatBoxHeaderLeft {
}

.chatBoxMain {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  background: #ffffff;
  height: 50rem;
}

.chatFrame{
  width: 40rem;
  height: 50rem;
}

.ClosedChatBox{
  border-top: 1px solid $grey-200;
  border-bottom:1px solid $grey-200; 
}

.ClosedChatBoxLeft{
  padding: 1rem 1rem;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  border-left: 1px solid $grey-200;
  background: $white;
}

.ClosedChatBoxRight{
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border-right: 1px solid $grey-200;
  background: $white;
  padding: 1rem 1rem 1rem 0rem;
}

.boxShadow{
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  border-radius: 2rem;
}