@import "../../../../styles/variables.scss";
.header {
    padding: 32px 25px 6px;
    background: white;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 1px 4px 0px rgba(28, 28, 28, 0.06);
    height: 125px;
  }
  
  .headerIcon {
    width: 56px;
    height: 56px;
    margin-right: 10px;
  }
  
  .ctaIcon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
  
  .headerContent {
    display: flex;
    align-items: center;
  }
  
  .switchOutletCta {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    // border: 1px solid $red-400;
    cursor: pointer;
  }
  
  .navbarDropdown {
    position: absolute;
    background-color: #fff;
    top: 30px;
    right: 0;
    min-width: 255px;
    padding: 0 15px;
    border-radius: 8px;
    box-shadow: $box-shadow-card-med;
    width: max-content;
    z-index: 20;
    .section {
      border-bottom: 1px solid $gray-light;
      &:first-of-type {
        margin-top: 15px;
      }
      &:last-of-type {
        border: 0;
        margin-bottom: 15px;
      }
      .dropdownItem {
        margin: 12px 0;
        font-size: 14px;
        word-break: break-all;
        .icon {
          position: relative;
          top: 2px;
        }
        .imageIcon {
          width: 16px;
          position: relative;
          top: 2px;
        }
        .text {
          margin-left: 12px;
          margin-right: 4px;
        }
        .logout {
          color: $red-border;
          font-weight: 600;
          &:hover {
            color: darken($red-border, 10%);
          }
        }
        .switchOutletBtn {
          text-align: center;
          padding: 12px 0;
          font-weight: 600;
          color: $sec-red;
          border: 1px solid $red-border;
          border-radius: 4px;
          display: block;
        }
        .ml12px {
          margin-left: 12px;
        }
        .amountBg {
          background: $red-100;
          color: $red-border;
          border: 1px solid $red-number;
          padding: 2px 8px;
          border-radius: 12px;
          font-size: 12px;
          font-family: "OurLexend-Medium";
          white-space: nowrap;
          display: inline-block;
          margin-left: 25px;
          margin-top: 5px;
        }
        .newBg {
          background-color: $red-400;
          color: #fff;
          padding: 2px 8px;
          border-radius: 12px;
          margin-left: 12px;
          font-size: 12px;
        }
      }
    }
  }
  
  .navbarSidebarDropdown {
    @media (max-width: $md-screen) {
      padding-bottom: 100px;
    }
    position: relative;
    background-color: #f2f4f7;
    width: 100%;
    .section {
      padding: 20px 26px 0;
      .dropdownItemWrapper {
        border-radius: 20px;
        border: 1px solid $gray-200;
        padding: 16px 12px;
        background: white;
        box-shadow: 0px 1px 4px 0px rgba(28, 28, 28, 0.06);
      }
      .dropdownItem {
        font-size: 14px;
        word-break: break-all;
        display: flex;
        justify-content: space-between;
        .icon {
          position: relative;
          top: 2px;
        }
        .sideIcon {
          width: 8px;
          height: 13px;
        }
        .imageIcon {
          width: 14px;
          position: relative;
          top: 2px;
        }
        .text {
          margin-left: 12px;
          margin-right: 4px;
          font-family: "OurLexend-Medium", Helvetica, sans-serif;
          //color: $gray-900;
        }
        .logout {
          color: $sushi-red;
          font-weight: 600;
          &:hover {
            color: darken($sushi-red, 10%);
          }
        }
        .switchOutletBtn {
          text-align: center;
          padding: 12px 0;
          font-weight: 600;
          color: $sec-red;
          border: 1px solid $red-border;
          border-radius: 4px;
          display: block;
        }
        .ml12px {
          margin-left: 12px;
        }
        .amountBg {
          background: $teal-400;
          color: #fff;
          padding: 1px 5px;
          border-radius: 100px;
          font-size: 11px;
          font-family: "OurLexend-Medium";
          white-space: nowrap;
          display: inline-block;
          margin-right: 10px;
        }
        .newBg {
          background-color: $red-400;
          color: #fff;
          padding: 2px 8px;
          border-radius: 12px;
          margin-left: 12px;
          font-size: 12px;
        }
      }
    }
  }
  
  .modalCont {
    width: 700px;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
  
  .fixedWidth {
    width: 300px;
  
    @media (max-width: 992px) {
      width: 200px;
    }
  }
  
  .newIcon {
    background-color: $red-400;
    color: white;
    padding: 1px 5px;
    border-radius: 100px;
    margin-right: 10px;
  }
  
  .bannerContainer {
    padding: 20px 25px 6px;
  }
  
  .bannerDetailContainer {
    display: flex;
    padding: 12px 20px;
    border-radius: 100px;
    justify-content: space-between;
    z-index: 12;
    position: relative;
    margin-top: -50px;
  }
  
  .horizontalCenter {
    display: flex;
    align-items: center;
  }
  .horizontalAndVerticalCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bannerSubHeading {
    display: flex;
    border-radius: 100px;
    padding: 4px 8px;
    align-items: center;
  }
  .bannerRightIcon {
    margin-left: 4px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .suffixIcon {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  .textStyle {
    font-weight: 600;
    font-size: 16px;
  }
  .textStyleSubHeading {
    font-weight: 600;
    font-size: 14px;
  }
  